// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-button-class {
    z-index: 99;
    box-shadow: "0px 0px 15px lightgray";
    background: #d7d7d7;
}

.back-button-class:focus {
    background: #9b9a9a;
}`, "",{"version":3,"sources":["webpack://./src/app/Shared/Components/GoBackButton/GoBackButton.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,oCAAoC;IACpC,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".back-button-class {\n    z-index: 99;\n    box-shadow: \"0px 0px 15px lightgray\";\n    background: #d7d7d7;\n}\n\n.back-button-class:focus {\n    background: #9b9a9a;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
