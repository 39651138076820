import * as React from 'react';

const TBChestMatrix = ({ flag, matrix }) => {
    return (
        <div>
            {flag.length ? (
                <div>
                    <div className='font-semibold text-3xl my-5 text-gray-600'>AI Generated Diagnosis</div>
                    <div className='grid grid-cols-3 bg-gray-100 shadow-md rounded gap-5 p-4 mt-3 pb-5'>
                        {flag.map((item, index) => (
                            <div key={index} className='text-xs text-center border-2 rounded-lg p-4 bg-red-500 font-medium text-white'>
                                {item}
                            </div>
                        ))}
                    </div>
                    <div className='font-semibold text-3xl my-5 text-gray-600'>Next Steps</div>
                    <div className='bg-gray-100 shadow-md rounded p-5 my-10'>
                        Please Consult to a trained Radiologist.
                    </div>
                </div>
            ) : (
                <>
                    <div className='text-3xl font-semibold'>
                        Features AI will look for
                    </div>
                    <div className='grid grid-cols-3 bg-gray-100 shadow-md rounded gap-5 p-4 mt-3 pb-5'>
                        {matrix}
                    </div>
                </>
            )}
        </div>
    )
}

export default TBChestMatrix;