import React from "react";
import NavBar from "../../NavBar/NavBar.js";
import ProblemCard from "../../../../Shared/Components/ProblemCard/ProblemCard.js";
import ModelCard from "../../../../Shared/Components/ModelCard/ModelCard.js";
import ImpactCard from "../../../../Shared/Components/ImpactCard/ImpactCard.js";
import TimelineCardWrapper from "../../../../Shared/Components/TimelineCardWrapper/TimelineCardWrapper.js";
import "./MediaSurveillance.css";
import { idsp } from "../../../../Constants/Solutions/idsp-constants.js";
import { getIDSPAccessToken } from "../../../../Actions/global.js";

const MediaSurveillance = () => {
    function Open() {
        try {
            var access_token = getIDSPAccessToken();
        } catch (error) {
            console.log(error);
            Navigate(-1);
        }
        const url = `https://demo-idsp.wadhwaniai.org/?token=${access_token}`;
        window.open(url, "_blank");
    }

    return (
        <div>
            <div>
                <NavBar path={idsp.nextSolution} demo={idsp.demo} demoType={"External"} funcOpen={Open} demoUrl={""} />
            </div>
            <div className="grid grid-cols-3 py-[0.8rem] px-6 gap-2">
                <div className="col-span-2">
                    <div className="flex gap-6">
                        <ProblemCard backgroundImage={idsp["problem-image"]} problem={idsp.problem} />
                        <ModelCard model_heading={idsp.model_heading} model_question={idsp.model_question} model_answer={idsp.model_answer} />
                    </div>
                    <div className="flex flex-col pr-4 mt-6">
                        <TimelineCardWrapper information={idsp.timeline_information} />
                        <div className="opacity-80 text-[#9DA0A4] col_text mt-1">Collaboration with National Centre for Disease Control (NCDC)</div>
                    </div>
                </div>
                <div className="col-span-1">
                    <div className="h-full">
                        <ImpactCard information={idsp.information} content={idsp.content_array} sdgContent={idsp.sdgContent} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MediaSurveillance;