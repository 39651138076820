import React from "react";
import NavBar from "../../NavBar/NavBar.js";
import ProblemCard from "../../../../Shared/Components/ProblemCard/ProblemCard.js";
import ModelCard from "../../../../Shared/Components/ModelCard/ModelCard.js";
import ImpactCard from "../../../../Shared/Components/ImpactCard/ImpactCard.js";
import TimelineCardWrapper from "../../../../Shared/Components/TimelineCardWrapper/TimelineCardWrapper.js";
import "./Dermatology.css";
import { dermatology } from "../../../../Constants/Solutions/derma-constants.js";

const Dermatology = () => {
  return (
    <div>
        <div>
            <NavBar path={dermatology.nextSolution} demo={dermatology.demo} demoUrl={""} demoType={"Internal"} />
        </div>
        <div className="grid grid-cols-3 py-[0.8rem] px-6 gap-2">
            <div className="col-span-2">
                <div className="flex gap-6">
                    <ProblemCard backgroundImage={dermatology["problem-image"]} ratio={dermatology["problem-ratio"]} problem={dermatology.problem}/>
                    <ModelCard model_heading={dermatology.model_heading} model_question={dermatology.model_question} model_answer={dermatology.model_answer}/>
                </div>
                <div className="flex flex-col pr-4 mt-6">
                    <TimelineCardWrapper information={dermatology.timeline_information}/>
                    <div className="opacity-80 text-[#9DA0A4] col_text mt-1">Collaboration with AIIMS Delhi and Ministry of Health and Family Welfare</div>
                </div>
            </div>
            <div className="col-span-1">
                <div className="h-full">
                    <ImpactCard information={dermatology.information} content={dermatology.content_array} sdgContent={dermatology.sdgContent}/>
                </div>
            </div>
        </div>
    </div>
  )
};

export default Dermatology;