// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-health {
    /* padding-left: 65px; */
    padding-top: 30px;
    background-position: top -3rem right 0rem;
    background-repeat: no-repeat;
    background-size: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.health-heading {
    font-size: 55px;
    font-weight: 700;
    line-height: 70px;
    color: #2C8259;
    text-align: center;
}

.health-content {
    margin-top: 1rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    width: 45vw;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/Core/Components/Health/Health.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,iBAAiB;IAGjB,yCAAyC;IACzC,4BAA4B;IAC5B,qBAAqB;IACrB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;;AAGA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".main-health {\n    /* padding-left: 65px; */\n    padding-top: 30px;\n    display: flex;\n    flex-direction: column;\n    background-position: top -3rem right 0rem;\n    background-repeat: no-repeat;\n    background-size: 35vw;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n\n.health-heading {\n    font-size: 55px;\n    font-weight: 700;\n    line-height: 70px;\n    color: #2C8259;\n    text-align: center;\n}\n\n.health-content {\n    margin-top: 1rem;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 25px;\n    width: 45vw;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
