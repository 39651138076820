import React, { useEffect, useState } from "react";
import "./TimelineCardWrapper.css";
import TimelineCard from "../TimelineCard/TimelineCard";

const TimelineCardWrapper = ({information}) => {
    let heading = "Our Solution Workflow:";
    const [highlightedIndex, setHighlightedIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
          setHighlightedIndex((prevIndex) => (prevIndex + 1) % information.length);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

  return (
    <div className="timeline_wrapper w-[95%]">
        <div className="text-[#456496] text-lg font-semibold">{heading.toUpperCase()}</div>
        <div className="flex justify-evenly items-center timelinecard_div">
            {information.map((card, index) => (
              <>
                <TimelineCard key={index} text={card.information} image_url={card.image} index={index} highlightedIndex={highlightedIndex}/>
                {index < information.length-1
                  ? (
                    <div className="flex gap-1">
                      <div className="w-[0.3rem] h-[0.3rem] bg-[#909090] rounded-full" />
                      <div className="w-[0.3rem] h-[0.3rem] bg-[#909090] rounded-full" />
                      <div className="w-[0.3rem] h-[0.3rem] bg-[#909090] rounded-full" />
                      <div className="w-[0.3rem] h-[0.3rem] bg-[#909090] rounded-full" />
                    </div>
                  ) : (<></>)}
              </>
            ))}
        </div>
    </div>
  )
}

export default TimelineCardWrapper