export let diabetic_retinopathy = {
    "problem": ["Ophthalmologist to population ratio:", "Rural - 1: 250,000", "Urban - 1: 100,000"],
    "problem-image": "/Assets/Images/reti_problem.png",
    "model_heading": "Solution: AI Powered Screening for Diabetic Retinopathy",
    "model_question": "An AI solution for screening of diabetic retinopathy",
    "model_answer": "Solution analyses fundus cameras images to identify DR severity grade",
    "content_array": [
        {"content" : "Early detection to mitigate the risk of vision loss and DR-related complications", "image": "/Assets/Images/Reti_Content1.svg"},
        {"content" : "Prioritise focus on patients needing immediate care", "image": "/Assets/Images/Reti_Content2.svg"},
        {"content" : "Prevent blindness in ~101 million diabetic population through timely screening", "image": "/Assets/Images/Reti_Content3.svg"},
    ],
    "timeline_information": [
        {"information": "Fundus camera captures retinal image", "image": '/Assets/Images/RetinalImage1.svg'},
        {"information": "AI analyses image and gives DR severity", "image": '/Assets/Images/RetinalImage2.svg'},
        {"information": "First responders analyse the AI result", "image": '/Assets/Images/RetinalImage3.svg'},
        {"information": "Refer high-risk patients to ophthalmologist", "image": '/Assets/Images/RetinalImage4.svg'}
    ],
    "information": {
        "heading": "Impact",
        "number1": "91.7%",
        "numbertext": "accuracy results",
        "image": "/Assets/Images/retinoImpact.svg",
        "imagetext": "Brand Independent"
    },
    "sdgContent": {
        "image": "/Assets/Images/reti_sdg.svg",
        "heading": "Contributes to SDG 3.4:",
        "content": "Noncommunicable diseases"
    },
    "nextSolution": "/health/solutions/tbchestxray",
    "demo": true
};