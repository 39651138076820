import React from "react";
import "./NavBar.css";
import { useNavigate } from "react-router-dom";
import { login } from "../../../Actions/global";

const NavBar = ({ path, demo, demoUrl, demoType, funcOpen = () => { } }) => {
  let navigate = useNavigate();

  const handleBack = () => {
    navigate("/health/solutions");
  };
  const handleNextSolution = () => {
    navigate(path);
  }
  const handleDemos = async () => {
    // await login();
    if (demoType == 'Internal') {
      navigate(window.location.pathname + "/demo")
    }
    else if (demoUrl !== "" && demoType == 'External') {
      window.open(demoUrl, "_blank")
    }
    else if (demoUrl === "") {
      funcOpen();
    }
  }

  return (
    <div className="flex h-[7vh] w-screen justify-between px-6 py-6 items-center navbar">
      <button className="sol_text flex bg-[#e6d4cf] text-[#404040] rounded-md px-5 py-1 items-center cursor-pointer text-lg" onClick={() => handleBack()}>
        <img src={'/Assets/Images/BackIcon.svg'} style={{ width: "1.7vw", height: "2vh", marginRight: "4px" }} />
        Back to Solutions
      </button>
      <div className="flex gap-6">
        {demo && <button className="sol_text flex bg-[#d4edf7] text-[#404040] rounded-md px-5 py-1 items-center cursor-pointer text-lg"
          onClick={() => handleDemos()}
        >
          <img src={'/Assets/Images/ExperinceIcon.svg'} style={{ width: "1.2vw", height: "1.55vh", marginRight: "4px" }} />
          Experience Demo
        </button>}
        {!window.location.pathname.includes('/opd') && <button className="sol_text flex bg-[#d4edf7] text-[#404040] rounded-md px-5 py-1 items-center cursor-pointer text-lg" onClick={() => handleNextSolution()}>
          Go to Next Solution
          <img src={'/Assets/Images/NextIcon.svg'} style={{ width: "1.2vw", height: "1.55vh", marginLeft: "4px" }} />
        </button>}
      </div>
    </div>
  )
}

export default NavBar;