// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline_wrapper {
    box-shadow: 0px 0px 5px 6px #00000003;
    border-radius: 20px;
    margin-right: 4px;
}

.timelinecard_div {
    padding: 10px 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/Shared/Components/TimelineCardWrapper/TimelineCardWrapper.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".timeline_wrapper {\n    box-shadow: 0px 0px 5px 6px #00000003;\n    border-radius: 20px;\n    margin-right: 4px;\n}\n\n.timelinecard_div {\n    padding: 10px 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
