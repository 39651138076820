import React from "react";
import "./InformationCard.css";

const InformationCard = ({information}) => {
  return (
    <div className="information_card">
        <div className="text-[#12356D] pt-4 opacity-0 impact_word">{information.heading}</div>
        <div className="grid grid-cols-7 mt-2">
            {information.image ? (<div className="col-span-3  text-center grid gap-2">
                <div className="information_number">{information.number1}</div>
                <div className="information_text">{information.numbertext}</div>
            </div>) : (<div className="col-span-3  text-center grid gap-2">
                <div className="information_number">{information.number1}</div>
                <div className="information_text">{information.numbertext}</div>
            </div>)}
            {information.uppermiddletext || information.middletext
            ?
                (
                <div className="col-span-1 text-center flex justify-center items-center">
                    <div className="information_text">{information.uppermiddletext}</div>
                    <div className="information_text">{information.middletext}</div>
                </div>
                ):(<div className="col-span-[1vw]"></div>)
            }
            <div className="col-span-3 text-center flex flex-col items-center gap-2">
                {information.image
                ?
                    (
                    <div className="w-[8rem] h-[5vh] flex justify-center items-center">
                        <img src={information.image} className="w-[100%] h-[100%]"/>
                    </div>
                    ) : (
                    <div className="information_number">
                        {information.number2}
                    </div>
                    )
                }
                <div className="information_text">{information.imagetext}</div>
            </div>
        </div>
    </div>
  )
}

export default InformationCard