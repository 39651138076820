export let solutions_data = [
    {
        "id": 1,
        "name": "Diabetic Retinopathy",
        "category": "Facility",
        "filter": "R&D",
        "description": "An AI-powered solution for diabetic retinopathy screening at the onset stage, to improve patient health outcomes.",
        "icon": '/Assets/Images/retinopathy.svg',
        "path": "/health/solutions/retinopathy",
        "backgroundColor": "#F44336",
    },
    {
        "id": 2,
        "name": "Teleradiology",
        "category": "Facility",
        "filter": "R&D",
        "description": "An image based tool for screening 17 radiological features in chest X-rays",
        "icon": "/Assets/Images/tbChest.svg",
        "path": "/health/solutions/tbchestxray",
        "backgroundColor": "#E81E63",
    },
    {
        "id": 3,
        "name": "Dermatology",
        "category": "Facility",
        "filter": "R&D",
        "description": "An AI Solution for screening of 14 skin conditions.",
        "icon": "/Assets/Images/dermatology.svg",
        "path": "/health/solutions/dermatology",
        "backgroundColor": "#9C27B0",
    },
    {
        "id": 4,
        "name": "Media Disease Surveillance",
        "category": "Administration",
        "filter": "Deployed",
        "description": "An AI-based media surveillance tool for event based disease tracking",
        "icon": "/Assets/Images/mediaSurveillance.svg",
        "path": "/health/solutions/mediasurveillance",
        "backgroundColor": "#673AB7",
    },
    {
        "id": 5,
        "name": "Clinical Decision Support System",
        "category": "Facility",
        "filter": "Deployed",
        "description": "A suite of AI solutions to assist in decision making for medical professionals",
        "icon": "/Assets/Images/cdss.svg",
        "path": "/health/solutions/cdss",
        "backgroundColor": "#3F51B5",
    },
    {
        "id": 6,
        "name": "ASHA Health Assistant",
        "category": "Facility",
        "filter": "R&D",
        "description": "A conversational AI agent to aid in knowledge retrieval of queries",
        "icon": "/Assets/Images/asha.svg",
        "path": "/health/solutions/ashasaheli",
        "backgroundColor": "#03A9F4"
    },
    {
        "id": 7,
        "name": "Cough Against TB",
        "category": "Community",
        "filter": "Deployed",
        "description": "An AI powered screening tool that uses cough sounds and symptom data for the early identification of pulmonary TB.",
        "icon": "/Assets/Images/cough.svg",
        "path": "/health/solutions/coughtb",
        "backgroundColor": "#00BCD4",
    },
    {
        "id": 8,
        "name": "Line Probe Assay",
        "category": "Facility",
        "filter": "R&D",
        "description": "An AI-powered solution for the automated reading, interpretation and transmission of LPA test results.",
        "icon": "/Assets/Images/tlbs.svg",
        "path": "/health/solutions/tblineprobeassay",
        "backgroundColor": "#009688",
    },
    {
        "id": 9,
        "name": "TB Patient Risk Stratification",
        "category": "Community",
        "filter": "R&D",
        "description": "Predicting loss to follow-up (LFU) and mortality among TB patients at the onset of treatment using AI.",
        "icon": "/Assets/Images/tbLoss.svg",
        "path": "/health/solutions/tblosstofollowup",
        "backgroundColor": "#4CAF50",
    },
    {
        "id": 10,
        "name": "Vulnerability Mapping",
        "category": "Community",
        "filter": "R&D",
        "description": "An AI model to identify and monitor TB vulnerable regions",
        "icon": "/Assets/Images/tbMapping.svg",
        "path": "/health/solutions/tbvulnerability",
        "backgroundColor": "#FFC002",
    },
    {
        "id": 11,
        "name": "Newborn Anthropometry",
        "category": "Facility",
        "filter": "R&D",
        "description": "An AI solution to identify newborns at risk",
        "icon": "/Assets/Images/newbornanthro.svg",
        "path": "/health/solutions/newbornanthro",
        "backgroundColor": "#FF9800",
    },
    {
        "id": 12,
        "name": "Under 6 Anthropometry",
        "category": "Facility",
        "filter": "R&D",
        "description": "An AI solution for monitoring growth measurements in children",
        "icon": "/Assets/Images/anthropometry.svg",
        "path": "/health/solutions/under6anthro",
        "backgroundColor": "#FF5722",
    },
    {
        "id": 13,
        "name": "OPD Recommendation",
        "category": "Community",
        "filter": "R&D",
        "description": "An AI solution that recommends OPD based on chief complaints",
        "icon": "/Assets/Images/opd.png",
        "path": "/health/solutions/opd",
        "backgroundColor": "#795548",
    },
    // {
    //     "id": 14,
    //     "name": "Smart Interactive Patient Form",
    //     "category": "Facility",
    //     "filter": "R&D",
    //     "description": "An AI-powered solution for symptom collection",
    //     "icon": "/Assets/Images/sipf.svg",
    //     "path": "/health/solutions/sipf",
    //     "backgroundColor": "#9E9E9E",
    // },
];