import React from "react";
import "./ContentCard.css";

const ContentCard = ({content, image_url}) => {
  return (
    <div className="flex bg-[#FDFCFC] gap-6 rounded-xl p-2 justify-start items-center content_card">
        <img src={image_url} style={{width: "3.7vw", height: "6.1vh"}} />
        <div className="text-[#404040] content_text">
          {Array.isArray(content)
            ? content.map((line, index) => (
                <div key={index}>{index>0? <span>&nbsp;&nbsp;&#8226;</span>: null}{line}</div>
              ))
          : content}
        </div>
    </div>
  )
}

export default ContentCard