import React from "react";
import NavBar from "../../NavBar/NavBar.js";
import ProblemCard from "../../../../Shared/Components/ProblemCard/ProblemCard.js";
import ModelCard from "../../../../Shared/Components/ModelCard/ModelCard.js";
import ImpactCard from "../../../../Shared/Components/ImpactCard/ImpactCard.js";
import TimelineCardWrapper from "../../../../Shared/Components/TimelineCardWrapper/TimelineCardWrapper.js";
import "./CDSS.css";
import { cdss } from "../../../../Constants/Solutions/cdss-constants.js";
import { getAccessToken } from "../../../../Actions/global.js";
import CustomModel from "../../../../Shared/Components/CustomModel/CustomModel.js";

const CDSS = () => {
    const [expandFlag, setExpandFlag] = React.useState(false);
    function Open() {
        try {
            var access_token = getAccessToken();
          } catch (error) {
            console.log(error);
            Navigate(-1);
          }
        // const url = `https://cdss-g20.wadhwaniai.org/?token=${access_token}`;
        const url = 'https://cdss-g20.wadhwaniai.org/?ref_id=testest&lang=1&token=953d3b79501d60495a5b0cd5ece7ba49&srcTyp=HWC&dob=30-08-1999&gendercode=1';
	window.open(url, "_blank");
    }

    const modelBody = (<img src={"/Assets/Images/cdss-sub-solution.png"} style={{width: "75%", height: "auto"}}/>)
  return (
    <div className="relative">
        <div>
            <NavBar path={cdss.nextSolution} demo={cdss.demo} demoType={"External"} funcOpen={Open} demoUrl={""}/>
        </div>
        <div className="grid grid-cols-3 py-[0.8rem] px-6 gap-2">
            <div className="col-span-2">
                <div className="flex gap-6">
                    <ProblemCard backgroundImage={cdss["problem-image"]} problem={cdss.problem}/>
                    <ModelCard model_heading={cdss.model_heading} model_question={cdss.model_question} model_answer={cdss.model_answer} showExpandButton={true} setExpandFlag={setExpandFlag}/>
                </div>
                <div className="flex flex-col pr-4 mt-6">
                    <TimelineCardWrapper information={cdss.timeline_information}/>
                    <div className="opacity-80 text-[#9DA0A4] col_text mt-1">Collaboration with AIIMS Rishikesh, Centre for Development and Advanced Computing (C-DAC) Mohali and NIC</div>
                </div>
            </div>
            <div className="col-span-1">
                <div className="h-full">
                    <ImpactCard information={cdss.information} content={cdss.content_array} sdgContent={cdss.sdgContent} />
                </div>
            </div>
        </div>
        <div className="absolute top-0 left-0 h-screen w-screen flex align-middle justify-center" style={{display: expandFlag ? 'block': 'none'}}>
            <CustomModel open={expandFlag} header={"CLINICAL DECISION SUPPORT SYSTEM: SUITE OF AI SOLUTIONS"} body={modelBody} close={setExpandFlag} />
        </div>
    </div>
  )
};

export default CDSS;
