import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getImages, getModelResults } from '../../../../../Actions/global';
import { BASE_ROUTE } from '../../../../../../env';
import InternalDemoWrapper from '../../../../../Shared/Components/InternalDemoWrapper/InternalDemoWrapper';
import { disease_data } from '../../../../../Constants/teleradio-constants';

const TBChestDemo = ({ }) => {
    let navigate = useNavigate();
    const defaultImage = "/Assets/Images/default-image.svg";
    const [isLoading, setIsLoading] = React.useState(false);
    const [flag, setFlag] = React.useState([]);
    const [image, setImage] = React.useState(defaultImage);
    const [imageData, setImageData] = React.useState();
    const [footText, setFootText] = React.useState("No Image Selected");
    const [borderColor, setBorderColor] = React.useState("border-gray-300");
    const [footerBackgroudColor, setFooterBackgroundColor] = React.useState("bg-gray-300")
    const [text, setText] = React.useState("select a picture");

    const matrix = disease_data.map(disease => {
        return (
            <div className={`text-xs text-center border-2 rounded px-2 py-3 ${flag.includes(disease.code) ? 'bg-red-500 font-medium text-white' : 'bg-gray-200 font-light'}`}>
                {disease.name}
            </div>
        )
    })

    React.useEffect(() => {
        getImagesData();
    }, []);

    React.useEffect(() => {
        setFootText("No Image Selected");
        setBorderColor("border-gray-300");
        setFooterBackgroundColor("bg-gray-300");
        setFlag(arr => []);
        getResults();
    }, [image])

    async function getImagesData() {
        let data = await getImages("TELERADIOLOGY");
        if (data) {
            setImageData(data);
        }
        else {
            navigate(-1);
        }
    }

    function handleClick() {
        setFootText("No Image Selected");
        setBorderColor("border-gray-300");
        setFooterBackgroundColor("bg-gray-300");
        setFlag(arr => []);
      }

    async function getResults() {
        if (image === defaultImage) {
            return;
        }
        let data = await getModelResults(text, "TELERADIOLOGY", image)
        if (data) {
            setIsLoading(false);
            const temp = [];
            Object.entries(data).map(([k, v]) => {
                if (v === 1) {
                    temp.push(k);
                }
            })
            if (temp.length) {
                setFootText("Abnormalities Detected")
                setBorderColor("border-red-500")
                setFooterBackgroundColor("bg-red-500")
            }
            else {
                setFootText("No Abnormalities Detected")
                setBorderColor("border-green-500")
                setFooterBackgroundColor("bg-green-500")
            }
            setFlag(temp);
        }
        else {
            navigate(-1);
        }
    }

        return (
            <div>
                <InternalDemoWrapper project={'Teleradiology'} isLoading={isLoading} title={"An AI-based computer vision solution to detect 17 radiological features in chest X-Rays."} backPath={-1} imageList={imageData} selectedImage={image} footText={footText} borderColor={borderColor} footerBackgroudColor={footerBackgroudColor} flag={flag} matrix={matrix} setImage={setImage} setText={setText} setIsLoading={setIsLoading} handleClick={handleClick} />
            </div>
        )
    }

    export default TBChestDemo;