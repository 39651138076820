export let asha = {
    "problem": "2.7M Frontline Healthcare Workers face issue of timely knowledge recollection",
    // Image to be updated
    "problem-image": "/Assets/Images/asha_problem.png",
    "model_heading": "Solution: ASHA health assistant",
    "model_question": "A conversational AI agent to aid in knowledge retrieval for delivering services",
    "model_answer": "The solution leverages the power of large language models (LLMs) to generate answers to healthcare queries asked by ASHAs",
    "content_array": [
        {"content" : "Facilitate evidence-based care through readily available knowledge", "image": "/Assets/Images/Derma_Content2.svg"},
        {"content" : "Reducing the likelihood of errors in delivering care", "image": "/Assets/Images/Reti_Content1.svg"},
        {"content" : "Empowerment of frontline healthcare workers", "image": "/Assets/Images/asha_Content3.svg"}
    ],
    "information": {
        "heading": "Impact",
    },
    "timeline_information": [
        {"information": "Identify knowledge base with information provided by government", "image": '/Assets/Images/ashaImage1.svg'},
        {"information": "LLM is trained on the knowledge base", "image": '/Assets/Images/RetinalImage2.svg'},
        {"information": "Chatbot to ask queries and retrieve relevant answers", "image": '/Assets/Images/cdssImage1.svg'},
        {"information": "Input support for multilingual speech and text", "image": '/Assets/Images/ashaImage4.svg'}
    ],
    "sdgContent": {
    "image": "/Assets/Images/asha_sdg.svg",
    "heading": "Contributes to SDG 3.c:",
    "content": "Health Financing and Workforce"
    },
    "nextSolution": "/health/solutions/coughtb",
    "demo": true,
    "demoUrl": "https://ehealth-genai-assistant-asha-demo.wadhwaniai.org/"
}