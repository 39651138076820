export let tblpa = {
    "problem": "0.4M LPA tests conducted manually every year",
    "problem-image": "/Assets/Images/tblpa_Problem.png",
    "model_heading": "Solution: tb line probe assay",
    "model_question": "An AI solution for automatic interpretation of LPA tests",
    "model_answer": "Solution diagnoses DR-TB cases by using computer vision to automate reading, interpretation and transmission of test results",
    "content_array": [
        {"content" : "Deployment in 87 CDST labs nationwide, estimated to impact 0.4M LPA tests", "image": "/Assets/Images/tblpa_Content1.svg"},
        {"content" : "Faster treatment initiation, ~0.2M patient- days saved annually", "image": "/Assets/Images/derma_Content2.svg"},
        {"content" : "~12K patients benefited through correct treatment regimen annually", "image": "/Assets/Images/Reti_Content1.svg"}
    ],
    "information": {
        "heading": "Impact",
        "number1": "97.9%",
        "numbertext": "band accuracy for 1st Line",
        "uppermiddletext": "and",
        "number2": "96.6%",
        "imagetext": "band accuracy for 2nd Line"
    },
    "timeline_information": [
        {"information": "Patients take the LPA test and submit it on Ni-Kshay", "image": '/Assets/Images/coughtbImage1.svg'},
        {"information": "AI generates a graph and performs analysis", "image": '/Assets/Images/RetinalImage2.svg'},
        {"information": "Human in the loop interprets low-confidence strips missed by algorithm", "image": '/Assets/Images/tblpaImage3.svg'},
        {"information": "Ni-Kshay results are disseminated to field", "image": '/Assets/Images/tblpaImage4.svg'}
    ],
    "sdgContent": {
        "image": "/Assets/Images/media_sdg.svg",
        "heading": "Contributes to SDG 3.3:",
        "content": "Infectious diseases"
    },
    "nextSolution": "/health/solutions/tblosstofollowup",
    "demo": true,
    "demourl": "https://lpa.wadhwaniai.org/dashboard/2",
}