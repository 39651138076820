// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category_card {
    width: 21.85vw;
    height: 16vh;
}

.category_name {
    font-family: "Lato";
    font-size: 3vh;
    line-height: 4vh;
}

.category_info {
    font-family: "Roboto Flex";
    font-size: 1.75vh;
    line-height: 2.5vh;
    /* color: #525F4F; */
}`, "",{"version":3,"sources":["webpack://./src/app/Shared/Components/CategoryCard/CategoryCard.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,iBAAiB;IACjB,kBAAkB;IAClB,oBAAoB;AACxB","sourcesContent":[".category_card {\n    width: 21.85vw;\n    height: 16vh;\n}\n\n.category_name {\n    font-family: \"Lato\";\n    font-size: 3vh;\n    line-height: 4vh;\n}\n\n.category_info {\n    font-family: \"Roboto Flex\";\n    font-size: 1.75vh;\n    line-height: 2.5vh;\n    /* color: #525F4F; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
