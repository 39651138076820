export let idsp = {
    "problem": "Daily manual media scanning: 20-30 newspapers and 300+ Google Alerts",
    "problem-image": "/Assets/Images/media_problem.png",
    "model_heading": "Solution: Media Disease Surveillance",
    "model_question": "An AI based media surveillance tool for event-based disease tracking",
    "model_answer": "AI surveillance tool crawls ~90K articles in 11 languages daily to extract unusual health events timely and efficiently",
    "content_array": [
        {"content" : "Early detection of 85% of all events of interest published", "image": "/Assets/Images/Reti_Content1.svg"},
        {"content" : "Early surveillance through detection of 91% of all outbreaks from media", "image": "/Assets/Images/Derma_Content3.svg"},
        {"content" : "1214+ alerts raised, 221 outbreaks confirmed from April’22-Jan’24", "image": "/Assets/Images/Derma_Content2.svg"}
    ],
    "timeline_information": [
        {"information": "AI model continuously crawls web for digital media data", "image": '/Assets/Images/mediaImage1.svg'},
        {"information": "Filters relevant events", "image": '/Assets/Images/mediaImage2.svg'},
        {"information": "Extracted events sent to IHIP", "image": '/Assets/Images/mediaImage3.svg'},
        {"information": "MSA reviews events & issues alerts", "image": '/Assets/Images/mediaImage4.svg'}
    ],
    "information": {
        "heading": "Impact",
    },
    "sdgContent": {
        "image": "/Assets/Images/media_sdg.svg",
        "heading": "Contributes to SDG 3.3:",
        "content": "Infectious diseases"
    },
    "nextSolution": "/health/solutions/cdss",
    "demo": true,
}