import React from "react";
import "./TimelineCard.css";

const TimelineCard = ({text, image_url, index, highlightedIndex}) => {

  return (
    <div className="pt-4 flex flex-col justify-center items-center">
      {/* <div className="bg-[#F9FAFC] rounded-full text-[#425E88] w-[2vw] h-[2vw] flex justify-center items-center">{index+1}</div> */}
        <div
          key={index}
          className={`flex flex-col items-center justify-evenly  py-4 px-1 card ${highlightedIndex === index ? "highlighted" : ""}`}
        >
          <img src={image_url} alt={`Card ${index + 1}`} style={{height: "10vh", width: "8vw"}}/>
          <p className={`text-[#425E88] px-2 text-center card_text ${highlightedIndex === index ? "" : "non_highlighted"}`}>{text}</p>
        </div>
    </div>
  )
}

export default TimelineCard