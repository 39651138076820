export let coughtb = {
    "problem": "1.8 out of prevalent 2.8 TB cases are missed",
    "problem-image": "/Assets/Images/coughtb_Problem.png",
    "model_heading": "Solution: Cough against TB",
    "model_question": "An AI solution for screening pulmonary tuberculosis (TB)",
    "model_answer": "AI-powered tool that utilizes cough sound, symptoms, and comorbidities for early detection & treatment",
    "content_array": [
        {"content" : "Early identification of potential pulmonary TB cases", "image": "/Assets/Images/Reti_Content1.svg"},
        {"content" : "Reduces TB under-reporting by affordable point-of-care screening ", "image": "/Assets/Images/Derma_Content2.svg"},
        {"content" : ["Till Jan 2024,", "83,332 individuals screened", "9,920 individuals flagged as Presumptive TB", "1,765 individuals tested", "65 TB+ cases diagnosed"], "image": "/Assets/Images/Derma_Content3.svg"}
    ],
    "information": {
        "heading": "Impact",
        "number1": "90.6%",
        "numbertext": "sensitivity ",
        "middletext": "and",
        "number2": "68.6%",
        "imagetext": "specificity"
    },
    "timeline_information": [
        {"information": "Individuals visit nearest HWC ", "image": '/Assets/Images/coughtbImage1.svg'},
        {"information": "Symptoms + cough audio collected via app", "image": '/Assets/Images/coughtbImage2.svg'},
        {"information": "AI analyzes the symptoms + cough sound", "image": '/Assets/Images/RetinalImage2.svg'},
        // Image to be changed
        {"information": "Assess presence of presumptive TB", "image": '/Assets/Images/coughImage4.svg'}
    ],
    "sdgContent": {
        "image": "/Assets/Images/media_sdg.svg",
        "heading": "Contributes to SDG 3.3:",
        "content": "Infectious diseases"
    },
    "nextSolution": "/health/solutions/tblineprobeassay",
    // "demourl": "https://main.dhkk7w6pc03nw.amplifyapp.com/",
    "demourl": "https://mobile.dhkk7w6pc03nw.amplifyapp.com/",
    "demo": true,
}