import React, { useState } from 'react';
import { LOGIN_URL } from '../../../../env';
// import EyeOpen from "/Assests/Images/eye.svg";
// import EyeClose from "/Assests/Images/eye-slash.svg";

function Login({ setUserLoggedIn}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    var formdata = new FormData();
    formdata.append("username", email);
    formdata.append("password", password);

    try {
        const response = await fetch(LOGIN_URL, {
            method: 'POST',
            body: formdata,
        });
        const data = await response.json();
        if (!response.ok) {
            console.log("request failed");
            console.log(data);
        } else {
            console.log(data);
            setUserLoggedIn(true);
            window.sessionStorage.setItem('token', data.token);
            window.sessionStorage.setItem('idsp_token', data.idsp_token);
        }
    } catch (error) {
        console.error('Error:', error);
    }
  }

  return (
    <>
    <div className="flex justify-center items-center h-screen">
      <form className="w-5/6 p-6 rounded-md border-2 border-gray-300 shadow-md flex flex-col items-center">
        <h2 className="mb-4 text-3xl font-medium">Login</h2>
        <div className="mb-4">
          <input
            className="shadow-md rounded-md p-2 border-2 border-gray-300 w-full"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            placeholder="Email"
          />
        </div>
        <div className="relative mb-4">
          <input
            className="shadow-md rounded-md p-2 border-2 border-gray-300 w-full"
            type={passwordVisible ? "text" : "password"}
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
            placeholder="Password"
          />
          {/* <button
            type="button"
            className="absolute top-0 right-0 mr-2 mt-2"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? <img src={EyeOpen} alt="EyeOpen"  width="20" height="20"/> : <img src={EyeClose} alt="EyeClose"  width="20" height="20"/>}
          </button> */}
        </div>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full self-center" onClick={handleSubmit}>
          Submit
        </button>
      </form>
    </div>
    </>
  );
}

export default Login;
