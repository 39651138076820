import * as React from 'react';

const DermatologyMatrix = ({flag, consult, matrix}) => {
    return (
        <div>
            {flag.length ? (
                        <div>
                            <div className='font-semibold text-3xl my-5 text-gray-600'>AI Generated Diagnosis</div>
                            <div className='bg-gray-100 shadow-md rounded p-5 grid grid-cols-2 gap-5 my-10'>
                            {flag.map((item, index) => (
                                    <div key={index} className={`text-md text-center border-2 rounded-lg p-4 capitalize ${item === 'normal' ? 'bg-green-500 font-medium text-white' : 'bg-red-500 font-medium text-white'}`}>
                                        {item}
                                    </div>
                                ))}
                            </div>
                            <div className='font-semibold text-3xl my-5 text-gray-600'>Next Steps</div>
                            {consult && <div className='bg-gray-100 shadow-md rounded p-5 my-10'>
                                Please consult to a trained Dermatalogist.
                            </div>}
                            {!consult && <div className='bg-gray-100 shadow-md rounded p-5 my-10'>
                                No need to consult to a trained Dermatologist.
                            </div>}
                        </div>
                    ) : (
                        <>
                            <div className='text-3xl font-semibold'>
                                Features AI will look for
                            </div>
                            <div className='grid grid-cols-3 bg-gray-100 shadow-md rounded gap-5 p-4 mt-3 pb-5'>
                                {matrix}
                            </div>
                        </>
                    )}
        </div>
    )
}

export default DermatologyMatrix;