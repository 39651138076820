// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sdg_card {
    border-radius: 10px;
    background: #4C9F38;
    box-shadow: 0px 0px 0px 0px rgba(4, 63, 99, 0.05), -1px 1px 2px 0px rgba(4, 63, 99, 0.05), -3px 2px 3px 0px rgba(4, 63, 99, 0.04), -6px 5px 4px 0px rgba(4, 63, 99, 0.03), -10px 8px 5px 0px rgba(4, 63, 99, 0.01), -16px 13px 6px 0px rgba(4, 63, 99, 0.00);
}

.content_heading {
    font-family: "Lato";
    font-size: 1.9vh;
    font-weight: 500;
}

.content_content {
    font-family: "Lato";
    font-size: 1.9vh;
    font-weight: 800;
}`, "",{"version":3,"sources":["webpack://./src/app/Shared/Components/SdgCard/SdgCard.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,4PAA4P;AAChQ;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".sdg_card {\n    border-radius: 10px;\n    background: #4C9F38;\n    box-shadow: 0px 0px 0px 0px rgba(4, 63, 99, 0.05), -1px 1px 2px 0px rgba(4, 63, 99, 0.05), -3px 2px 3px 0px rgba(4, 63, 99, 0.04), -6px 5px 4px 0px rgba(4, 63, 99, 0.03), -10px 8px 5px 0px rgba(4, 63, 99, 0.01), -16px 13px 6px 0px rgba(4, 63, 99, 0.00);\n}\n\n.content_heading {\n    font-family: \"Lato\";\n    font-size: 1.9vh;\n    font-weight: 500;\n}\n\n.content_content {\n    font-family: \"Lato\";\n    font-size: 1.9vh;\n    font-weight: 800;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
