import React from "react";
import "./FilterCard.css";
import GoBackButton from "../GoBackButton/GoBackButton";

const FilterCard = ({filters, onFilterChange}) => {
    const handleFilterClick = (filterName) => {
        onFilterChange(filterName, !filters[filterName]);
    };

    const renderCloseIcon = (filterName) => {
    return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer ml-1"
            viewBox="0 0 20 20"
            fill="currentColor"
            onClick={() => handleFilterClick(filterName)}
          >
            <path
              fillRule="evenodd"
              d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414L11.414 10l2.293 2.293a1 1 0 01-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        );
    };

  return (
    <div className="flex w-screen ml-[3.6vw] items-center">
      <GoBackButton path={"/health"} />
      <div className="flex gap-5 ml-[25vw]">
        <label className="filter_text py-2">Filters:</label>
        <div
            className={`filter_common flex items-center cursor-pointer p-2 rounded-xl ${filters.rd ? 'bg-[#71819C] text-[#FFFFFF]' : 'bg-[#F0F5F9] text-[#71819C]'}`}
            onClick={() => onFilterChange("rd", !filters.rd)}
        >
          Research & Development {filters.rd && renderCloseIcon('rd')}
        </div>
        <div
          className={`filter_common flex items-center cursor-pointer p-2 rounded-xl ${filters.deployed ? 'bg-[#71819C] text-[#FFFFFF]' : 'bg-[#F0F5F9] text-[#71819C]'}`}
          onClick={() => onFilterChange("deployed", !filters.deployed)}
        >
          Deployed {filters.deployed && renderCloseIcon('deployed')}
        </div>
      </div>
    </div>
  )
}

export default FilterCard