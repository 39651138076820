import * as React from 'react';
import GoBackButton from "../../../Shared/Components/GoBackButton/GoBackButton";
import KnowMainAICard from "../../../Shared/Components/KnowAIMainCard/KnowAIMainCard";
import ParticlesComponent from '../../../Shared/Components/Particles/ParticlesComponent';
import { knowAI } from "../../../Constants/know-ai-contants";
import "./KnowAI.css";
import KnowAIMainText from '../../../Shared/Components/KnowAIMainText/KnowAIMainText';


const KnowAI = () => {

    return (
        <div className='relative h-screen w-screen'>
            <ParticlesComponent count={70} speed={3} color={"#838383"} background={"#FFFFFF"} />
            <div className='fixed m-6 px-2'>
                <GoBackButton path={"/health"} />
            </div>
            <div className='w-screen h-[75vh] px-10 py-6 grid grid-cols-12'>
                <div className='col-span-3 flex flex-col align-center justify-center'>
                    <KnowAIMainText title={knowAI["main_page"]["title"]} sub_title={knowAI["main_page"]["sub_title"]} />
                </div>
                <div className='col-span-9 pl-24 flex flex-col align-center justify-center'>
                    <div className='grid grid-cols-12 gap-8'>
                        {
                            knowAI["main_page"]["main_cards"].map((card, index) => {
                                return <div key={index} className='col-span-4 flex-col align-center justify-center'>
                                    <KnowMainAICard index={index} background={card["background"]} shadow={card["shadow"]} image={card["image"]} title={card["title"]} description={card["description"]} path={card["path"]} />
                                </div>
                            }
                            )
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

export default KnowAI;