import * as React from 'react';
import "./CustomModel.css";

const CustomModel = ({ open, header, body, close = () => { } }) => {
    return (
        <div className='dialog-shadow h-[100%] w-screen'>
            <div className={'dialog-main p-6'} style={{maxWidth: "70%", margin: "0 auto"}}>
                <div className='text-md text-center'>
                    {header}
                </div>
                <div className='dialog-heading mt-6 flex align-middle justify-center'>
                    {body}
                </div>
                <div className='mt-6 flex align-middle justify-end'>
                    <button className='dialog-close-button px-4 py-2 cursor-pointer' onClick={() => close(false)}>Close</button>
                </div>
            </div>
        </div>
    )
}

export default CustomModel;