export let cdss = {
    "problem": "In India, there are only 0.7 doctors per 1,000 patients",
    //Image to be updated
    "problem-image": "/Assets/Images/cdss_problem.jpeg",
    "model_heading": "Solution: Clinical decision SUPPORT system",
    "model_question": "A suite of AI solutions to assist in decision making for medical professionals",
    "model_answer": "Solution to assist medical practitioners through consultations, diagnosis, treatment and adherence",
    "timeline_information": [
        {"information": "Collect patient chief complaints via Smart Interactive Patient Form", "image": '/Assets/Images/cdssImage1.svg'},
        {"information": "AI analyses and recommends diagnosis + treatment", "image": '/Assets/Images/cdssImage2.svg'},
        {"information": "Doctor validates AI diagnosis + treatment", "image": '/Assets/Images/cdssImage3.svg'},
        {"information": "Doctor prescribes treatment plan basis AI diagnosis", "image": '/Assets/Images/cdssImage4.svg'}
    ],
    "information": {
        "heading": "Impact",
        "number1": "81%",
        "numbertext": "Diagnosis accuracy",
        "middletext": "and",
        "number2": "64%",
        "imagetext": "Treatment accuracy"
    },
    "content_array": [
        {"content" : "25.4M AI assisted doctor-patient consultations till June 2023", "image": "/Assets/Images/Derma_Content2.svg"},
        {"content" : "Improve the quality of patient care by assisting ~1L CHOs", "image": "/Assets/Images/Reti_Content1.svg"},
        {"content" : "Assist healthcare professionals in diagnosis and treatment", "image": "/Assets/Images/Derma_Content3.svg"}
    ],
    "sdgContent": {
        "image": "/Assets/Images/cdss_sdg.svg",
        "heading": "Contributes to SDG 3.8:",
        "content": "Universal Health Coverage"
    },
    "nextSolution": "/health/solutions/ashasaheli",
    "demo": true,
}