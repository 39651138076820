export let tblfu = {
    "problem": `In 2021, 4% of TB patients were deemed "lost to follow-up" (LFU)`,
    "problem-image": "/Assets/Images/tblfu_problem.png",
    "model_heading": "Solution: Risk Stratification for TB Patients",
    "model_question": "An AI solution that classifies TB patients based on predicted risk of mortality and other adverse outcomes such as LFU",
    "model_answer": "The solution identifies high-risk patients and enables the NTEP to carry out interventions and allocate resources based on this information",
    "content_array": [
        {"content" : "Early detection to lower the risk of developing and spreading drug-resistant TB", "image": "/Assets/Images/Reti_Content1.svg"},
        {"content" : ["In 2023, improved targeted care through", "Covering 75 districts in 3 states", "Serving ~1 lakh patients", "Averting 1.4k cases of LFU"], "image": "/Assets/Images/tblfu_Content1.svg"},
    ],
    "information": {
        "heading": "Impact",
        "number1": "35%",
        "numbertext": "efficiency ",
        "uppermiddletext": "aims to save",
        "number2": "75%",
        "imagetext": "patients from LFU and/or mortality"
    },
    "timeline_information": [
        {"information": "AI analyzes the Input Notification Register from Ni-Kshay", "image": '/Assets/Images/tblfuImage1.svg'},
        {"information": "AI calculates risk score for every TB patient", "image": '/Assets/Images/RetinalImage2.svg'},
        {"information": "Patients classified as high or low risk", "image": '/Assets/Images/tblfuImage2.svg'},
        {"information": "State-specific interventions planned for high-risk patients", "image": '/Assets/Images/coughtbImage1.svg'}
    ],
    "sdgContent": {
        "image": "/Assets/Images/media_sdg.svg",
        "heading": "Contributes to SDG 3.3:",
        "content": "Infectious diseases"
    },
    "nextSolution": "/health/solutions/tbvulnerability",
    "demo": true,
    "demourl": "https://tb-clamp.wadhwaniai.org/login",
}