import * as React from 'react';

const LoadingResult = () => {
    return (
        <div className="absolute z-10 top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-white border-8 border-white m-2">
            <div className="flex items-center justify-center ">
                <div className="w-40 h-40 border-t-4 border-l-4 border-dotted border-black rounded-full animate-spin"></div>
            </div>
            <div className='text-center text-4xl mt-4'>
                Computing Result
            </div>
        </div>
    )
}

export default LoadingResult;