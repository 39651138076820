import { GET_URLS, POST_URLS, LOGIN_URL, USERNAME, PASSWORD } from "../../env";
import fetchApi from "./fetchApi";

export const login = async () => {
    var formdata = new FormData();
    formdata.append("username", USERNAME);
    formdata.append("password", PASSWORD);
    try {
        const response = await fetch(LOGIN_URL, {
            method: 'POST',
            body: formdata,
        });
        const data = await response.json();
        if (!response.ok) {
            console.log("request failed");
            console.log(data);
        } else {
            console.log(data);
            window.sessionStorage.setItem('token', data.token);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}


export function getAccessToken() {
    try {
        const token = sessionStorage.getItem("token");
        if (!token) {
            throw new Error("No token found in session storage");
        }
        return token;
    } catch (error) {
        throw error;
    }
}

export function getIDSPAccessToken() {
    try {
        const token = sessionStorage.getItem("idsp_token");
        if (!token) {
            throw new Error("No token found in session storage");
        }
        return token;
    } catch (error) {
        throw error;
    }
}

export async function getImages(project) {
    try {
        var access_token = getAccessToken();
    } catch (error) {
        console.log(error);
        return null
    }

    const requestOptions = {
        method: "GET",
        headers: {
            'token': `${access_token}`
        },
    };
    const response = await fetch(`${GET_URLS}?project=${project}`, requestOptions);
    const data = await response.json();
    if (!response.ok) {
        console.log("got an error");
        console.log("vipin");
        return null
    }
    else {
        console.log(data);
        return data
    }
}

export async function getModelResults(text, project, image) {
    try {
        var access_token = getAccessToken();
    } catch (error) {
        console.log(error);
        return null;
    }

    var formdata = new FormData();
    formdata.append("tag", text);
    formdata.append("project", project);
    formdata.append("url", image);

    const requestOptions = {
        method: "POST",
        body: formdata,
        headers: {
            'token': `${access_token}`,
        },
        redirect: 'follow'
    };

    const response = await fetch(POST_URLS, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        console.log("you got an error");
        return null;
    } else {
        console.log(data);
        return data;
        }
}
