import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ImageBox from '../../../../../Shared/Components/ImageBox/ImageBox';
import { getImages, getModelResults } from '../../../../../Actions/global';
import { disease_data } from '../../../../../Constants/derma-constants.js';
import LoadingResult from '../../../../../Shared/Components/LoadingResult/LoadingResult';
import InternalDemoWrapper from '../../../../../Shared/Components/InternalDemoWrapper/InternalDemoWrapper';
import { BASE_ROUTE } from '../../../../../../env';


const DermatologyDemo = () => {
    let navigate = useNavigate();
    const defaultImage = "/Assets/Images/default-image.svg";
    const [isLoading, setIsLoading] = React.useState(false);
    const [flag, setFlag] = React.useState([]);
    const [image, setImage] = React.useState(defaultImage);
    const [imageData, setImageData] = React.useState();
    const [footText, setFootText] = React.useState("No Image Selected");
    const [borderColor, setBorderColor] = React.useState("border-gray-300");
    const [footerBackgroudColor, setFooterBackgroundColor] = React.useState("bg-gray-300");
    const [consult, setConsult] = React.useState(false);
    const [text, setText] = React.useState("select a picture");

    const matrix = disease_data.map(disease => {
        return (
            <div className={`text-xs text-center border-2 rounded px-2 py-3 ${flag.includes(disease.code) ? 'bg-red-500 font-medium text-white' : 'bg-gray-200 font-light'}`}>
                {disease.Name}
            </div>
        )
    })

    React.useEffect(() => {
        getImagesData();
    }, []);

    React.useEffect(() => {
        setFootText("No Image Selected");
        setBorderColor("border-gray-300");
        setFooterBackgroundColor("bg-gray-300");
        setFlag(arr => []);
        getResults();
    }, [image])

    function handleClick() {
        setFootText("No Image Selected");
        setBorderColor("border-gray-300");
        setFooterBackgroundColor("bg-gray-300");
        setFlag(arr => []);
    }

    async function getImagesData() {
        let data = await getImages("DERMATOLOGY");
        if (data) {
            setImageData(data);
        }
        else {
            navigate(-1);
        }
    }

    async function getResults() {
        if (image === defaultImage) {
            return;
        }
        let data = await getModelResults(text, "DERMATOLOGY", image)
        if (data) {
            setIsLoading(false);
            setFlag(arr => [data['predicted_class_name']]);
            if (data['predicted_class_name'] === 'normal') {
                setConsult(false);
                setFootText("No Abnormalities Detected");
                setBorderColor("border-green-500");
                setFooterBackgroundColor("bg-green-500");
            } else {
                setConsult(true);
                setFootText("Disease Detected");
                setBorderColor("border-red-500");
                setFooterBackgroundColor("bg-red-500");
            }
        }
        else {
            navigate(-1);
        }

    }

    return (
        <div>
            <InternalDemoWrapper project={'Dermatology'} isLoading={isLoading} title={"An AI-based computer vision solution to detect 10 types of skin diseases."} backPath={-1} imageList={imageData} selectedImage={image} footText={footText} borderColor={borderColor} footerBackgroudColor={footerBackgroudColor} flag={flag} consult={consult} matrix={matrix} setImage={setImage} setText={setText} setIsLoading={setIsLoading} handleClick={handleClick} />
        </div>
    )
}

export default DermatologyDemo;