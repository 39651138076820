import qs from "query-string";

const fetchApi = (url, method = "GET", body = {}, headers = new Headers(), params = {}) => {
    const obj = { method, headers, body }
    if (method === 'DELETE') {
        url = qs.stringifyUrl({ url: url, query: body });
        delete obj.body
    }

    if (method === 'GET') {
        delete obj.body
    }

    if (JSON.stringify(params) !== JSON.stringify({})) {
        var data = params
        var newURLSearchParams = url + "?"
        for (var k in data) {
            newURLSearchParams = newURLSearchParams + encodeURIComponent(k) + "=" + encodeURIComponent(data[k]) + "&"
        }
        newURLSearchParams = newURLSearchParams.slice(0, -1)
        console.log(newURLSearchParams)
        console.log(obj);
        return fetch(newURLSearchParams, obj)
            .then(response => response.json())
            .then(jsondata => { return jsondata })
            .catch(e => console.log("error", e))
    }
    return fetch(url, obj).then(res => {
        return res.json()
    }).catch(e => console.log("error", e))
}


export default fetchApi;
