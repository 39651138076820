import React from "react";
import NavBar from "../../NavBar/NavBar.js";
import ProblemCard from "../../../../Shared/Components/ProblemCard/ProblemCard.js";
import ModelCard from "../../../../Shared/Components/ModelCard/ModelCard.js";
import ImpactCard from "../../../../Shared/Components/ImpactCard/ImpactCard.js";
import TimelineCardWrapper from "../../../../Shared/Components/TimelineCardWrapper/TimelineCardWrapper.js";
import "./CoughTB.css";
import { coughtb } from "../../../../Constants/Solutions/cough-constants.js";

const CoughTB = () => {
  return (
    <div>
        <div>
            <NavBar path={coughtb.nextSolution} demo={coughtb.demo} demoType={"External"} demoUrl={coughtb.demourl}/>
        </div>
        <div className="grid grid-cols-3 py-[0.8rem] px-6 gap-2">
            <div className="col-span-2">
                <div className="flex gap-6">
                    <ProblemCard backgroundImage={coughtb["problem-image"]} problem={coughtb.problem}/>
                    <ModelCard model_heading={coughtb.model_heading} model_question={coughtb.model_question} model_answer={coughtb.model_answer}/>
                </div>
                <div className="flex flex-col pr-4 mt-6">
                    <TimelineCardWrapper information={coughtb.timeline_information}/>
                </div>
            </div>
            <div className="col-span-1">
                <div className="h-full">
                    <ImpactCard information={coughtb.information} content={coughtb.content_array} sdgContent={coughtb.sdgContent}/>
                </div>
            </div>
        </div>
    </div>
  )
};

export default CoughTB;