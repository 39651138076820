import React, { useEffect, useState } from 'react'
import DropDownMenu from '../DropdownMenu/DropdownMenu';

function ImageBox({data, color, footText, borderColor, footerBackgroudColor, image, setImage= () => {}, setText = () => { }, setIsLoading=()=>{}, handleClick=() =>{}}) {
    const [open, setOpen] = useState(false);
    return (
        <div className='my-2'>
            <div className='bg-black w-2/3 my-2 rounded' onClick={() => {setOpen(!open); handleClick();}}>
                <div className='flex flex-row justify-around items-center p-2'>
                    <div className='text-white text-md font-medium'>
                        Select a Picture
                    </div>
                    <div className=''>
                        <img src={"/Assets/Images/dropdown.svg"} alt='drop down icon'/>
                    </div>
                </div>
                {open ? (
                    <DropDownMenu
                        data={data}
                        setText={setText}
                        setImage={setImage}
                        color={color}
                        image={image}
                        setIsLoading = {setIsLoading}
                    />
                ) : (
                    <></>
                )}
            </div>
            <div className={`border-4 h-full ${borderColor} rounded-lg ${footerBackgroudColor}`}>
                <div className={`w-full bg-gray-200 grid place-items-center rounded-t-lg`}>
                    <img className='h-[350px]' src={image} alt='main image'/>
                </div>
                <div className={`${footerBackgroudColor} p-2 text-lg font-medium text-white rounded-b-lg`}>
                    {footText}
                </div>
            </div>
        </div>
    )
}

export default ImageBox;
