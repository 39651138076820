import * as React from 'react';
import GoBackButton from "../../../../../Shared/Components/GoBackButton/GoBackButton";
import { CLASSIFICATION_GAME_URL } from '../../../../../../env';

const Classification = () => {
    return (
        <div className='relative h-screen w-screen'>
            <div className='fixed m-6 px-2' style={{zIndex: 9999}}>
                <GoBackButton path={"/health/knowai"} />
            </div>
            <iframe className='h-[100%] w-[100%]' src={CLASSIFICATION_GAME_URL} sandbox='allow-scripts' />
        </div>
    )
}

export default Classification;