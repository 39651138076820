import * as  React from 'react';

const Header = () => {


    return (
        <div className='fixed flex justify-between items-center px-6 max-h-fit w-screen' style={{borderBottom: "1px solid lightgray", background: "white", height: "4rem", maxHeight: "4rem", zIndex: 9999}}>
            <img className='w-full h-[2.5rem] sm:w-auto' src={'/Assets/Images/wiaiLogo.png'} alt='wiai' />
            <img className='w-full h-[3rem] sm:w-auto' src={'/Assets/Images/usaidLogo.png'} alt='usaid' />
            <img className='w-full h-[2.5rem] sm:w-auto' src={'/Assets/Images/health-govern.svg'} alt='wadhwani' />
            <img className='w-full h-[3.8rem] sm:w-auto' src={'/Assets/Images/traceTBLogo.png'} alt='traceTB' />
            <img className='w-full h-[2.5rem] sm:w-auto' src={'/Assets/Images/g-20.svg'} alt='g-20' />
            {/* <img className='w-full h-[2.5rem] sm:w-auto' src={'/Assets/Images/indImage.png'} alt='independence' /> */}
        </div>
    )
}

export default Header;