// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col_text {
    font-family: "Lato";
    font-weight: 500;
    font-size: 1.75vh;
}

.source_text {
    font-family: "Roboto Flex";
    font-weight: 300;
    font-size: 1.3vh;
}`, "",{"version":3,"sources":["webpack://./src/app/Core/Components/Solutions/DiabeticRetinopathy/DiabeticRetinopathy.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".col_text {\n    font-family: \"Lato\";\n    font-weight: 500;\n    font-size: 1.75vh;\n}\n\n.source_text {\n    font-family: \"Roboto Flex\";\n    font-weight: 300;\n    font-size: 1.3vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
