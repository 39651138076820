import * as React from 'react';
import "./KnowAIMainCard.css";
import { useNavigate } from 'react-router-dom';

const KnowAIMainCard = ({index, background, shadow, image, title, description, path}) => {
    const imagePath = `/Assets/Images/${image}`;
    let navigate = useNavigate();
    
    function openComponent(path) {
        navigate(path)
    }

    return (
        <div className='relative flex flex-col align-center justify-center rounded-lg h-[50vh]' onClick={() => openComponent(path)} style={{boxShadow: `-10px 10px 0px ${shadow}`}}>
            <div className='p-4 rounded-lg h-full' style={{background: background}}>
                <div className='absolute right-0 top-0 rounded-md m-3 p-1 flex align-center justify-center' style={{background: shadow}}>
                    <img src={"/Assets/Images/cardArrow.png"} style={{height:"0.4rem"}} />
                </div>
                <img src={imagePath} className='mt-5'/>
                <div>
                    <div className='text-2xl font-[500] mt-5 text-center card-title' style={{color: shadow}}>
                        {title}
                    </div>
                    <div className='text-[0.90rem] text-center' style={{color: shadow}}>
                        {description}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KnowAIMainCard;