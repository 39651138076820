// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter_text {
    font-family: "Roboto";
    font-size: 2vh;
    line-height: 2.7vh;
    font-weight: 300;
    color: #4F505F;
}

.filter_common {
    font-family: "Roboto";
    font-size: 2.3vh;
    line-height: 3vh;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/Shared/Components/FilterCard/FilterCard.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".filter_text {\n    font-family: \"Roboto\";\n    font-size: 2vh;\n    line-height: 2.7vh;\n    font-weight: 300;\n    color: #4F505F;\n}\n\n.filter_common {\n    font-family: \"Roboto\";\n    font-size: 2.3vh;\n    line-height: 3vh;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
