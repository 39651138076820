import * as Reacet from 'react';

const DiabeticRetinopathyMatrix = ({flag, referable, nonReferable, after_report_map, matrix}) => {
    return (
        <div>
            {flag.length ? (
                    <div>
                        <div className='font-semibold text-2xl my-5 text-gray-600'>AI Generated Diagnosis</div>
                        <div className='bg-gray-100 shadow-md rounded p-5 grid grid-cols-2 gap-5 my-10'>
                            {flag.map((item, index) => (
                                <div className={`text-md text-center border-2 rounded-lg p-4 ${item === 'No Diabetic Retinopathy' ? 'bg-green-500 font-medium text-white' : 'bg-red-500 font-medium text-white'}`}>
                                    {item}
                                </div>
                            ))}
                            {referable && (
                                <div className={`text-lg text-center border-2 rounded px-2 py-3 ${referable ? 'bg-white text-red-600 border-red-500 font-medium' : 'bg-gray-200 font-light'}`}>
                                    Referable
                                </div>
                            )}
                            {nonReferable && (
                                <div className={`text-lg text-center border-2 rounded px-2 py-3 ${nonReferable ? 'bg-white text-green-600 border-green-500 font-medium' : 'bg-gray-200 font-light'}`}>
                                    Non Referable
                                </div>
                            )}

                        </div>
                        <div className='font-semibold text-2xl my-5 text-gray-600'>Next Steps</div>
                        <div className='bg-gray-100 shadow-md rounded p-5 my-10'>
                            {after_report_map[flag[0]]}
                        </div>
                    </div>
                ) : (
                    <>
                        <div className='text-3xl font-semibold'>
                            Features AI will look for
                        </div>
                        <div className='grid grid-cols-3 bg-gray-100 shadow-md rounded gap-6 p-3 overflow-y-auto'>
                            <div className='col-span-1 grid gap-6'>
                                {matrix}
                            </div>
                            <div className='col-span-1 grid grid-rows-5 gap-6'>
                                <div className={`text-lg text-center border-2 rounded px-2 py-3 ${nonReferable ? 'bg-white text-green-600 border-green-500 font-medium' : 'bg-gray-200 font-light'}`}>
                                    Non Referable
                                </div>
                                <div className={`text-lg text-center border-2 rounded px-2 py-3 ${referable ? 'bg-white text-red-600 border-red-500 font-medium' : 'bg-gray-200 font-light'}`}>
                                    Referable
                                </div>
                            </div>
                        </div>
                    </>
                )}
        </div>
    )   
}

export default DiabeticRetinopathyMatrix;