export let sipf = {
    "problem": "Patient's complaint collection practice is currently fragmented",
    "problem-image": "/Assets/Images/sipf_problem.svg",
    "model_heading": "Solution: smart interactive patient form",
    "model_question": "An AI-powered solution for symptom collection",
    "model_answer": "Solution collects relevant information from patients in the line of diagnosis via a Smart Interactive Patient Form (SIPF)",
    "content_array": [
        {"content" : "Reduced waiting times by optimizing doctor’s bandwidth for consultation", "image": "/Assets/Images/Derma_Content2.svg"},
        {"content" : "Extensive and seamless collection of patient’s chief complaintss", "image": "/Assets/Images/tblpa_Content1.svg"},
    ],
    "information": {
        "heading": "impact",
    },
    "timeline_information": [
        {"information": "Patient selects relevant symptoms", "image": '/Assets/Images/opdImage2.svg'},
        {"information": "AI prompts leading questions", "image": '/Assets/Images/RetinalImage2.svg'},
        {"information": "AI recommends OPD & diagnosis", "image": '/Assets/Images/cdssImage1.svg'},
        {"information": "Doctors leverage symptom summary & diagnosis", "image": '/Assets/Images/sipfImage4.svg'}
    ],
    "sdgContent": {
        "image": "/Assets/Images/cdss_sdg.svg",
        "heading": "Contributes to SDG 3.8:",
        "content": "Universal Health Coverage"
    },
    "demo": false
}