export let tbchestxray = {
    "problem": "India has 1 radiologist per 0.1M population",
    "problem-image": "/Assets/Images/tblfu_problem.png",
    "model_heading": "Solution: Chest X-ray Radiological Feature Detection",
    "model_question": "An AI solution for screening radiological features in chest X-rays",
    "model_answer": "Solution that screens 17 radiological abnormalities and triages emergency cases in healthcare facilities",
    "content_array": [
        {"content" : "Early detection of life-threatening conditions ", "image": "/Assets/Images/tbchest_Content1.svg"},
        {"content" : "Reduced missed findings, improving accuracy", "image": "/Assets/Images/tbchest_Content2.svg"},
        {"content" : "Diagnostic services in areas where radiological expertise is unavailable", "image": "/Assets/Images/Derma_Content3.svg"}
    ],
    "information": {
        "heading": "Impact",
        "number1": "82%",
        "numbertext": "Accuracy",
        "middletext": "by",
        "image": "/Assets/Images/tbchest_Impact.svg",
        "imagetext": "Smart Automation"
    },
    "timeline_information": [
        {"information": "Practitioners seek X-ray diagnoses on eCollabDDS", "image": '/Assets/Images/tbchestImage1.svg'},
        {"information": "AI generates a preliminary report, verified by radiologists", "image": '/Assets/Images/RetinalImage2.svg'},
        {"information": "Radiologists send modified report to practitioners", "image": '/Assets/Images/tblpaImage3.svg'},
        {"information": "Assist in treatment or referral based diagnosis", "image": '/Assets/Images/Derma_Image4.svg'}
    ],
    "sdgContent": {
        "image": "/Assets/Images/reti_sdg.svg",
        "heading": "Contributes to SDG 3.4:",
        "content": "Noncommunicable diseases"
    },
    "nextSolution": "/health/solutions/dermatology",
    "demo": true
}