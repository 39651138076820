// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.solution_image {
    /* width: 7vw; */
    height: 100%;
    box-shadow: 3.4638988971710205px 1.7319494485855103px 10.39169692993164px 5.19584846496582px #0000000D;
    padding: 1.1vh 1.1vw;
}

.solution_name {
    font-family: "Roboto";
    font-size: 2.6vh;
    font-weight: 500;
    line-height: 3.25vh;
    letter-spacing: 0em;
    color: #5B5B5B;
}

.solution_desc {
    font-family: "Roboto";
    font-size: 1.4vh;
    font-weight: 400;
    line-height: 1.9vh;
    letter-spacing: 0em;
    text-align: left;
    color: #5B5B5B;
    margin-top: 0.7vh;
}`, "",{"version":3,"sources":["webpack://./src/app/Shared/Components/SolutionCard/SolutionCard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,sGAAsG;IACtG,oBAAoB;AACxB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".solution_image {\n    /* width: 7vw; */\n    height: 100%;\n    box-shadow: 3.4638988971710205px 1.7319494485855103px 10.39169692993164px 5.19584846496582px #0000000D;\n    padding: 1.1vh 1.1vw;\n}\n\n.solution_name {\n    font-family: \"Roboto\";\n    font-size: 2.6vh;\n    font-weight: 500;\n    line-height: 3.25vh;\n    letter-spacing: 0em;\n    color: #5B5B5B;\n}\n\n.solution_desc {\n    font-family: \"Roboto\";\n    font-size: 1.4vh;\n    font-weight: 400;\n    line-height: 1.9vh;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #5B5B5B;\n    margin-top: 0.7vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
