import * as React from 'react';
import "./DomainCard.css";


const DomainCard = ({ bgImage, title, description, path, clicked = () => { } }) => {
    return (
        <div className='relative'>
            <div className='absolute right-0 top-0 rounded-lg m-4 p-2 flex align-center justify-center' style={{background: "rgba(0,0,0)"}}>
                <img src={"/Assets/Images/cardArrow.png"} style={{height:"0.5rem"}} />
            </div>
            <div className='domain-card' style={{ backgroundImage: "linear-gradient(0deg, rgba(0 0 0 / 40%), rgba(0 0 0 / 40%)), url('" + bgImage + "')" }} onClick={() => clicked(path)}>
                <h2 className='text-5xl' style={{fontWeight: 600}}>{title}</h2>
                <h4 className='text-xl'>{description}</h4>
            </div>
        </div>
    )
}

export default DomainCard;