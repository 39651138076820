export let under6anthro = {
    "problem": "Malnutrition is the top cause of death in children under 5",
    "problem-image": "/Assets/Images/under6_Problem.png",
    "model_heading": "Solution: Under 6 anthropometry",
    "model_question": "An AI solution for monitoring growth measurement of children",
    "model_answer": "Solution to measure growth by capturing height and weight of children (6 months - 6 yrs) using smartphone videos",
    "content_array": [
        {"content" : "Reduce errors in weight estimation of LBW by helping frontline workers", "image": "/Assets/Images/Derma_Content3.svg"},
        {"content" : "Precise growth measurements to assist decision-making for associated health programs", "image": "/Assets/Images/under6_Content2.svg"},
        { "content": "Enhances access and eliminates the need for heavy anthropometric instruments", "image": "/Assets/Images/under6_Content3.svg"}
    ],
    "information": {
        "heading": "impact",
    },
    "timeline_information": [
        {"information": "HCW takes video of child using the Digital Tarazu app", "image": '/Assets/Images/newbornImage1.svg'},
        {"information": "AI measures child’s weight & height from video", "image": '/Assets/Images/RetinalImage2.svg'},
        {"information": "HCW makes recommendations based on signs", "image": '/Assets/Images/under6Image3.svg'},
        {"information": "Real-time LBW prevalence updates to DB", "image": '/Assets/Images/mediaImage3.svg'}
    ],
    "sdgContent": {
        "image": "/Assets/Images/newborn_sdg.svg",
        "heading": "Contributes to SDG 3.2:",
        "content": "Neonatal and child mortality"
    },
    "nextSolution": "/health/solutions/opd",
    "demo": false
}