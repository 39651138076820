import * as React from 'react';
import "./DecisionTree.css";
import GoBackButton from '../../../../../Shared/Components/GoBackButton/GoBackButton';
import { DECISION_GAME_URL } from '../../../../../../env';


const DecisionTree = ({ bgColor }) => {
    // const [currentSlide, setCurrentSlide] = React.useState(1);

    // // Scroll Functions
    // function StartGame() {
    //     let ele = document.getElementById(1);
    //     ele.scrollIntoView({ behavior: "smooth" });
    // }

    // const getBack = (slide) => {
    //     let ele = document.getElementById(slide);
    //     if (slide == 0) {
    //         ele = document.getElementById("back-button");
    //         setCurrentSlide(1);
    //     }
    //     else {
    //         setCurrentSlide(slide);
    //     }
    //     ele.scrollIntoView({ behavior: "smooth" });
    // }

    // const getNext = (slide) => {
    //     let ele = document.getElementById(slide);
    //     setCurrentSlide(slide);
    //     if (slide == 7) {
    //         setCurrentSlide(1);
    //         ele = document.getElementById(1);
    //     }
    //     ele.scrollIntoView({ behavior: "smooth" });
    // }



    return (
        // <div id="main-div" className='h-screen w-screen relative overflow-hidden' style={{ background: "#edeeed" }}>
        //     <div id="back-button" className='w-[100%] bg-[#edeeed] p-4 absolute'>
        //         <GoBackButton bg={"#d7d7d7"} path={"/health/knowai"} />
        //     </div>
        //     <div className='h-screen w-screen mt-10'>
        //         <div id="introduction" className='h-screen w-screen relative'>
        //             <div className='p-10 text-lg w-[75%]'>
        //                 <div className='flex align-middle justify-start'>
        //                     Hi!
        //                     <img style={{ height: "1rem" }} src={'/Assets/IMages/pic.gif'} />
        //                     , AI Researcher
        //                 </div>
        //                 <br />
        //                 <div>
        //                     Welcome to AI Clustering! As an AI researcher, you will train a model to cluster a bunch of animals into groups by identifying similarities between them.
        //                 </div>
        //                 <div>
        //                     Let's get started!
        //                 </div>
        //             </div>
        //             <button className='px-5 py-2 absolute bottom-[15rem] right-[5%] start-button' onClick={StartGame}>Let's Start</button>
        //         </div>
        //         <div id="slides" className="h-[75%] w-[100%] scrolling-wrapper">
        //             <div id="1" className="item h-[100%] w-screen px-4">
        //                 <div className='w-screen p-4' style={{ background: "#edeeed" }}>
        //                     <span className="text-3xl heading">Dataset</span>
        //                     <br />
        //                     <span>Initially, we require a dataset that acts as an example to teach the machine learning algorithm how to make predictions.</span>
        //                     <div className='w-[100%] flex align-middle justify-center'>
        //                         <img className='h-[22rem]' src={'/Assets/Images/clustering-1.gif'} />
        //                     </div>
        //                 </div>
        //             </div>
        //             <div id="2" className="item h-[100%] w-screen px-4">
        //                 <div className='w-screen p-4' style={{ background: "#edeeed" }}>
        //                     <span className="text-3xl heading">Data Splitting</span>
        //                     <br />
        //                     <br />
        //                     <span>The first step in our classification task is to randomly split our pets into three independent sets:</span>
        //                     <br />
        //                     <ul className='ml-4' style={{ listStyleType: "circle" }}>
        //                         <li>
        //                             <b>Training Set -</b> Model learns patterns
        //                         </li>
        //                         <li>
        //                             <b>Validation Set -</b> Assess model performance
        //                         </li>
        //                         <li>
        //                             <b>Test Set -</b> Evaluate unbiased accuracy
        //                         </li>
        //                     </ul>
        //                     <div className='w-[100%] flex align-middle justify-center'>
        //                         <img className='h-[25rem]' src={'/Assets/Images/clustering-1.gif'} />
        //                     </div>
        //                 </div>
        //             </div>
        //             <div id="3" className="item h-[100%] w-screen px-4">
        //                 <div className='w-screen p-4' style={{ background: "#edeeed" }}>
        //                     <span className="text-3xl heading">Training the Model</span>
        //                     <br />
        //                     <br />
        //                     <span className="text-xl">Identifying Similarities</span>
        //                     <br />
        //                     <span>Now, let’s go ahead and train our model with the dataset: <br />
        //                         AI model learns to make predictions or perform specific tasks through model training.
        //                         <br />
        //                         The model does not understand the difference between each data point in the dataset.
        //                         <br />
        //                         Through unsupervised learning, algorithms learn patterns and similarities in the data
        //                         <br />
        //                         without labels and groups into clusters.</span>
        //                     <div className='w-[100%] flex align-middle justify-center'>
        //                         <img className='h-[22rem]' src={'/Assets/Images/Word-Cloud.gif'} />
        //                     </div>
        //                 </div>
        //             </div>
        //             <div id="4" className="item h-[100%] w-screen px-4">
        //                 <div className='w-screen p-4 flex' style={{ background: "#edeeed" }}>
        //                     <div className='flex-col-3'>
        //                         <span className="text-3xl heading">Training the Model</span>
        //                         <br />
        //                         <br />
        //                         <span className="text-xl">Clustering Approach</span>
        //                         <br />
        //                         <span>But wait, there are a plethora of clustering algorithms available : <br />
        //                             (Below are few famous ones listed)
        //                         </span>
        //                         <br />
        //                         <br />
        //                         <ul className='ml-4' style={{ listStyleType: "circle" }}>
        //                             <li>
        //                                 Hierarchical Clustering
        //                             </li>
        //                             <li>
        //                                 <b>K-Means Clustering</b>
        //                             </li>
        //                             <li>
        //                                 Gaussian Mixture Model (GMM)
        //                             </li>
        //                             <li>
        //                                 Agglomerative Clustering
        //                             </li>
        //                         </ul>
        //                         <br />
        //                         <span>
        //                             Let’s use <b>K-Means Clustering</b>  today!
        //                             <br />
        //                             <br />
        //                             The clustering model groups similar data points into three clusters using <br />
        //                             the identified patterns and similarities.
        //                         </span>
        //                         <br />
        //                         <br />
        //                         <span>
        //                             <b>None :</b>
        //                             <br />
        //                             All animals will be grouped together
        //                             <br />
        //                             <br />
        //                             <b>Clustered :</b>
        //                             <ul>
        //                                 <li>
        //                                     Group 1 : Dogs and Cats
        //                                 </li>
        //                                 <li>
        //                                     Group 2: Fish Group 3: Birds
        //                                 </li>
        //                                 <li>
        //                                     Group 3: Birds Show one or 2 errors here.
        //                                     <br />
        //                                     Try to show 90% accuracy.
        //                                 </li>
        //                             </ul>

        //                         </span>
        //                     </div>
        //                     <div className='w-[100%] flex align-middle justify-center'>
        //                         <img className='h-[22rem]' src={'/Assets/Images/Training-cluster.gif'} />
        //                     </div>
        //                 </div>
        //             </div>
        //             <div id="5" className="item h-[100%] w-screen px-4">
        //                 <div className='w-screen p-4' style={{ background: "#edeeed" }}>
        //                     <span className="text-3xl heading">Validation & Testing the Model</span>
        //                     <br />
        //                     <br />
        //                     <span><b>Now that we have trained the model, we will access the model using a validation set, followed by a test set.</b>
        //                         <br />
        //                         How do you think the model performed??
        //                     </span>
        //                     <div className='w-[100%] flex align-middle justify-center'>
        //                         <img className='h-[22rem]' src={'/Assets/Images/clustering-1.gif'} />
        //                     </div>
        //                 </div>
        //             </div>
        //             <div id="6" className="item h-[100%] w-screen px-4">
        //                 <div className='w-screen p-4 flex flex-col align-top' style={{ background: "#edeeed" }}>
        //                     <span className="text-3xl heading">Congratulations</span>
        //                     <br />
        //                     <br />
        //                     <span><b>Yipee! You just built an AI Model. </b><br />
        //                         Your model will help group a bunch of animals into model-identified clusters.
        //                     </span>
        //                     <br />
        //                     <br />
        //                     <br />
        //                     <div id="welldone" className='pl-2 text-5xl'>
        //                         <b>Well done!</b>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className='flex align-middle justify-center p-4'>
        //             <button className='mx-2 py-2 bg-[#20618d] text-white text-xl text-center rounded-[10px] w-[8rem]' onClick={() => getBack(currentSlide - 1)}>Back</button>
        //             {currentSlide != 6 && <button className='mx-2 py-2 bg-black text-white text-xl text-center rounded-[10px] w-[8rem]' onClick={() => getNext(currentSlide + 1)}>Next</button>}
        //             {currentSlide == 6 && <button className='mx-2 py-2 bg-black text-white text-xl text-center rounded-[10px] w-[8rem]' onClick={() => { getBack(0); setCurrentSlide(1) }}>Exit</button>}
        //         </div>
        //     </div>
        // </div>
        // <div>Clustering</div>
        <div className='relative h-screen w-screen'>
            <div className='fixed m-6 px-2' style={{zIndex: 9999}}>
                <GoBackButton path={"/health/knowai"} />
            </div>
            <iframe className='h-[100%] w-[100%]' src={DECISION_GAME_URL} sandbox='allow-scripts' />
        </div>
    )   
}

export default DecisionTree;