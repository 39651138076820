import * as React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import Header from './Core/Components/Header/Header';
import Home from '../app/Core/Components/Home/Home';
import Health from "../app/Core/Components/Health/Health";
import Agriculture from "../app/Core/Components/Agriculture/Agriculture";
// Importing solution and its components
import Solutions from './Core/Components/Solutions/Solutions';
import DiabeticRetinopathy from './Core/Components/Solutions/DiabeticRetinopathy/DiabeticRetinopathy';
import Dermatology from './Core/Components/Solutions/Dermatology/Dermatology';
import MediaSurveilance from './Core/Components/Solutions/MediaSurveillance/MediaSurveillance';
import CDSS from './Core/Components/Solutions/CDSS/CDSS';
import CoughTB from './Core/Components/Solutions/CoughTB/CoughTB';
import TBLoss from './Core/Components/Solutions/TBLoss/TBLoss';
import TBLine from './Core/Components/Solutions/TBLine/TBLine';
import TBChest from './Core/Components/Solutions/TBChest/TBChest';
import NewBornAnthro from './Core/Components/Solutions/NewBornAnthro/NewBornAnthro';
import TBVulnerability from './Core/Components/Solutions/TBVulnerability/TBVulnerability';
import Under6Anthro from './Core/Components/Solutions/Under6Anthro/Under6Anthro';
import Asha from './Core/Components/Solutions/Asha/Asha';
import Opd from './Core/Components/Solutions/Opd/Opd';
import Sipf from './Core/Components/Solutions/Sipf/Sipf';
// Importing knowai and its components
import KnowAI from './Core/Components/KnowAI/KnowAI';
import Classification from './Core/Components/KnowAI/Components/Classification/Classification';
import DecisionTree from './Core/Components/KnowAI/Components/DecisionTree/DecisionTree';
import LLM from './Core/Components/KnowAI/Components/LLM/LLM';
import DiabeticRetinopathyDemo from './Core/Components/Solutions/DiabeticRetinopathy/Demo/DiabeticRetinopathyDemo';
import DermatologyDemo from './Core/Components/Solutions/Dermatology/Demo/DermatologyDemo';
import TBChestDemo from './Core/Components/Solutions/TBChest/TBChestDemo/TBChestDemo';
import { getAccessToken } from './Actions/global';
import Login from './Core/Components/Login/Login';
import { useState } from 'react';
import jwt_decode from 'jwt-decode'

const ProtectedRoute = ({
  element: Element,
  userLoggedIn,
  setUserLoggedIn,
}) => {
  if (userLoggedIn) {
    return <Element />;
  } else {
    return (
      <Login userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} />
    );
  }
};

function App() {
  var access_token = sessionStorage.getItem("token");
  const isTokenExpired = (token) => {
    try {
      if (token) {
        const decodedToken = jwt_decode(token);
        if (decodedToken && decodedToken.exp && decodedToken.exp > Date.now() / 1000) {
          return false;
        }
      }
    } catch (error) {
      console.error('Error decoding token:', error);
    }
    return true;
  };
  let isNotToken = isTokenExpired(access_token);
  const [userLoggedIn, setUserLoggedIn] = useState(!isNotToken);
  return (
    <div className='h-screen w-screen'>
      <Header />
      <div className='pt-[4rem]'>
        <Router>
          <Routes>
            <Route exact path='/' element={
              <ProtectedRoute element={Home} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            {/* Agriculture Routes */}
            <Route path='/agriculture' element={
              <ProtectedRoute element={Agriculture} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            {/* Health Routes */}
            <Route path='/health' element={
              <ProtectedRoute element={Health} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            {/* Solution Routes */}
            <Route path='/health/solutions' element={
              <ProtectedRoute element={Solutions} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/retinopathy" element={
              <ProtectedRoute element={DiabeticRetinopathy} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/dermatology" element={
              <ProtectedRoute element={Dermatology} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/mediasurveillance" element={
              <ProtectedRoute element={MediaSurveilance} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/cdss" element={
              <ProtectedRoute element={CDSS} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/coughtb" element={
              <ProtectedRoute element={CoughTB} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/tblosstofollowup" element={
              <ProtectedRoute element={TBLoss} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/tblineprobeassay" element={
              <ProtectedRoute element={TBLine} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/tbchestxray" element={
              <ProtectedRoute element={TBChest} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/newbornanthro" element={
              <ProtectedRoute element={NewBornAnthro} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/tbvulnerability" element={
              <ProtectedRoute element={TBVulnerability} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/under6anthro" element={
              <ProtectedRoute element={Under6Anthro} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/ashasaheli" element={
              <ProtectedRoute element={Asha} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/opd" element={
              <ProtectedRoute element={Opd} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/sipf" element={
              <ProtectedRoute element={Sipf} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            {/* Solution Demo Routes */}
            <Route path="/health/solutions/retinopathy/demo" element={
              <ProtectedRoute element={DiabeticRetinopathyDemo} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/dermatology/demo" element={
              <ProtectedRoute element={DermatologyDemo} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path="/health/solutions/tbchestxray/demo" element={
              <ProtectedRoute element={TBChestDemo} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            {/* Know AI Routes */}
            <Route path='/health/knowai' element={
              <ProtectedRoute element={KnowAI} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path='/health/knowai/classification' element={
              <ProtectedRoute element={Classification} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path='/health/knowai/decision-tree' element={
              <ProtectedRoute element={DecisionTree} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
            <Route path='/health/knowai/llm' element={
              <ProtectedRoute element={LLM} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn}/>
            }></Route>
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
