export let image_data = [
    {
        "index": 1,
        "name": "Image-1",
        "src": "/Assets/Images/g-20.svg"
    },
    {
        "index": 2,
        "name": "Image-2",
        "src": "https://g20-demo-data.s3.ap-south-1.amazonaws.com/g20_ophtha/1259_left.jpeg?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEYaCmFwLXNvdXRoLTEiRzBFAiAYcR9B6gguknDY3IUmoWXLM4q%2FodIN93C48wH3UfHAxgIhAKNPlQaWCOKo9b6Ty%2Fay94UmlYTSm8PwMw8%2FEneXitzzKpIDCJ%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMNjc0ODAxMTM1MTI5IgxTU2s%2Bdi9smphUpOUq5gKyQZfUWjCno2wS9qz3cSonB0wE0A65dYVI69PcmzKVEJj3ZddYz%2BV1nE5tdGTu51QhCTl9x2NjgHzwPFOfhcGRAE5EZMSyhNmrBnXrFQtounC%2Bs1zWcurtUACiRP0kteLeJmhTuKpXhpA2mQPFHQ49oaaPEGVdAxlr42CfJHFgOfKHZ1VwtNWbV9vnn0jE9EpPxE5iV13cb23rKj0%2F0A8k3uHG6PeFRLTPi5RKzJCkwAFDziqogQCuptTpw5EP14qN3dl5pHxl6IlK%2F%2FoklL6JiAnznxxXGVozPQd5hUAjFByh9PIm17NsEzQO4rAIolE%2Fz1FEI0BSAPwQeAdyUlPNk28d3PcreT0BnnTdNZZ6TgbPIQLNRcrvym%2Bpx62CGw6nFlYy%2BOyzItSrMQNli2ChAIs81ytv%2BJHDwQEPHLR%2Bs8aYzzaSlHgd%2Bp4TvLF8UQGRAl9wNtVGfR3%2FgGxtqf6tMr7RlBRvMOKe%2BZ0GOrMCX9v8WJ2OQ8Y8ekiX%2BDdAb4aOcKjrEF5kuupSCXUo4LhK22%2BLRp%2FAwAh6mbF8EHj07o5ho5UpxT5SN1TfKVo86z4ad7rh5Ofz22R%2BBWk2y%2BbqyvZORM2BNb9BynmphiWIgkWUBsURiNeHx6halHgZgsFdzywowMHkIQhCrxfR8SE4%2Fa8BmEFNpPaB8d4qlTvpRtGIRJGZuBDfXm%2Btttgvly4knweZ5A0HTM4PzXXFouGlAOaZA9S8vZM1Jc9Ruvcdt8HYg4V03VpNMcQ4%2BjxZMQLZKTCmBBn56fK%2Fs5CUPFscvJ3As%2BX%2B8MvnfXaYu7086%2FuNmoAKVDpusymuQLHRE%2FhMNJ90J9dbQ08GwUrE6pQ2jAEcb807BfhmdYfP9Xfg4Q7DkmewWLsQwJfQYuDp60z55g%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230111T065700Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Credential=ASIAZ2HKIFYMZEDPBSHL%2F20230111%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=c146afec1c3c4c99d597f5aef7c0dc5b2e614e7c557556fa44f3ef84ea189d2a"
    },
    {
        "index": 3,
        "name": "Image-3",
        "src": "/Assets/Images/bullets-1.png"
    }
]

export let disease_data = [
    {
        "id": 1,
        "Name": "Acne vulgaris",
        "code": "acne",
        "Category": "Inflammatory",
        "Name of Medication": "adapalene 0.1% gel OR Benzyl peroxide 2.5% gel OR tretinoin cream 0.5%",
        "Treatment Plan": "topical agents for 4-6 weeks",
        "Dosage": "once daily application",
        "Restrictions/Exceptions": "pregnancy",
        "Refer to Dermatologist": "If no improvement in 4 weeks",
        "Comments": "AVOID STEROIDS "
    },
    {
        "id": 2,
        "Name": "Alopecia areata",
        "code": "alopecia_areata",
        "Category": "Inflammatory",
        "Name of Medication": "clobetasol propionate lotion ",
        "Treatment Plan": "topical steroids",
        "Dosage": "once daily application",
        "Restrictions/Exceptions":"not on face",
        "Refer to Dermatologist": "if rapid increase in lesions",
        "Comments": ""
    },
    {
        "id": 3,
        "Name": "Eczema",
        "code": "eczema",
        "Category": "Inflammatory",
        "Name of Medication": "clobetasol propionate ointment",
        "Treatment Plan": "topical steroids, moisturisers, avoidance of inciting factors",
        "Dosage": "once daily application",
        "Restrictions/Exceptions":"not on face",
        "Refer to Dermatologist": "If no improvement in 4 weeks",
        "Comments": ""
    },
    {
        "id": 4,
        "Name": "Impetigo",
        "code": "impetigo",
        "Category": "Bacterial",
        "Name of Medication": "Oral Amoxicillin Syrup (weight-based dosage); topical fucidic acid/ neomycin/ soframycin/ mupirocin. In adults, can give Azithromycin 500mg once daily for 3 days",
        "Treatment Plan": "Topical antibiotics, oral antibiotics (if multiple lesions/ fever)",
        "Dosage": "oral dosage weight-based (2 or 3 times a day); twice daily local application",
        "Restrictions/Exceptions":"",
        "Refer to Dermatologist": "if rapid increase in lesions",
        "Comments": ""
    },
    {
        "id": 5,
        "Name": "Melasma",
        "code": "melasma",
        "Category": "Inflammatory",
        "Name of Medication": "kojic acid OR azelaic acid-based cream",
        "Treatment Plan": "topical depigmenting agents, strict light-protection",
        "Dosage": "twice daily application, tablet Fluconazoe 150 mg once every week for 4 weeks",
        "Restrictions/Exceptions":"oral treatment required for recurrent cases",
        "Refer to Dermatologist": "If no improvement in 4 weeks",
        "Comments": ""
    },
    {
        "id": 6,
        "Name": "Psoriasis",
        "code": "psoriasis",
        "Category": "Inflammatory",
        "Name of Medication": "clobetasol propionate 0.05% ointment, with salicylic acid 3% over thick lesions",
        "Treatment Plan": "topical agents plus moisturiser ",
        "Dosage": "once daily",
        "Restrictions/Exceptions":"",
        "Refer to Dermatologist": "If no improvement in 4 weeks",
        "Comments": "also refer if rapid lesions occur"
    },
    {
        "id": 7,
        "Name": "Scabies",
        "code": "scabies",
        "Category": "Parasitic",
        "Name of Medication": "permethrin 5% Cream",
        "Treatment Plan": "topical agent, two applications one week apart; PLUS family treatment PLUS washing of worn clothes simultaneously",
        "Dosage": "full tube, 30 gm, all over the body below neck including palms/soles/genitals; to be kept overnight and washed off in the morning",
        "Restrictions/Exceptions":"half the tube for children; for infants (less than 1 year), face and head also to be included ",
        "Refer to Dermatologist": "If no improvement in 4 weeks",
        "Comments": "all members of family staying with patient need to be treated simultaneously"
    },
    {
        "id": 8,
        "Name": "Tinea",
        "code": "tinea",
        "Category": "Fungal",
        "Name of Medication": "cyclopirox olamine 1% cream OR clotrimazole cream 0.1% cream; ORAL: cap itraconazole 200 mg ",
        "Treatment Plan": "topical and oral treatment; for 4-6 weeks or till lesions resolve completely",
        "Dosage": "topical: twice daily; oral: once daily",
        "Restrictions/Exceptions":"oral medications to be avoided in renal/ hepatic/ cardiac issues",
        "Refer to Dermatologist": "If no improvement in 4 weeks",
        "Comments": "AVOID STEROIDS AND COMBINATION CREAMS"
    },
    {
        "id": 9,
        "Name": "Warts",
        "code": "wrats",
        "Category": "Viral",
        "Name of Medication": "SALACTIN WART PAINT (EXCEPT FACE) overnight application with cover; FOR FACE: adapalene 0.1% gel OR tretinoin cream 0.025% OR salicyclic acid 6% ointment once daily; For genital warts, physician may apply podophyllin, 1-2 weeks apart, till lesions resolve upto 6 applications",
        "Treatment Plan": "topical retinoids for flat warts, podophyllin (doctor applied) for genital warts,",
        "Dosage": "topical retinoids: once daily",
        "Restrictions/Exceptions":"podophyllin only for adult genital lesions",
        "Refer to Dermatologist": "Yes immediately",
        "Comments": ""
    },
    {
        "id": 10,
        "Name": "Monkeypox",
        "code": "monkeypox",
        "Category": "Viral",
        "Name of Medication": "tablet or syrup paracetamol SOS for fever",
        "Treatment Plan": "Isolation, supportive care (hydration, rest) , cleanliness of lesions",
        "Dosage": "",
        "Restrictions/Exceptions":"",
        "Refer to Dermatologist": "Yes immediately",
        "Comments": ""
    },
    {
        "id": 11,
        "Name": "Other",
        "code": "other"
    }
]