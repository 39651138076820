// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-shadow {
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dialog-main {
    background: #FFF;
    border-radius: 10px;
    z-index: 1000;
    height: -moz-fit-content;
    height: fit-content;
}

.dialog-close-button {
    background: transparent;
    border: #eb7575;
    color: #eb7575;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #eb7575;
}

.dialog-close-button:hover {
    background: #eb7575;
    color: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/app/Shared/Components/CustomModel/CustomModel.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".dialog-shadow {\n    background: rgba(0, 0, 0, 0.4);\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.dialog-main {\n    background: #FFF;\n    border-radius: 10px;\n    z-index: 1000;\n    height: fit-content;\n}\n\n.dialog-close-button {\n    background: transparent;\n    border: #eb7575;\n    color: #eb7575;\n    text-align: center;\n    border-radius: 10px;\n    border: 1px solid #eb7575;\n}\n\n.dialog-close-button:hover {\n    background: #eb7575;\n    color: #FFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
