import React, { useState } from "react";
import "./Solutions.css";
import CategoryCard from "../../../Shared/Components/CategoryCard/CategoryCard";
import FilterCard from "../../../Shared/Components/FilterCard/FilterCard";
import SolutionGrid from "../../../Shared/Components/SolutionGrid/SolutionGrid";
import {solutions_data} from "../../../Constants/solutions-constants";



const categories = [
  {"name": "All", "information": "View all solutions"},
  {"name": "Community", "information": "Patients, Support Groups, NGOs, etc."},
  {"name": "Facility", "information": "Hospitals, clinics, primary health centers, diagnostic laboratories, etc."},
  {"name": "Administration", "information": "Healthcare administrators, government authorities, and regulatory bodies"}
];

const Solutions = () => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0].name);
  const [filters, setFilters] = useState({rd: false, deployed: false});

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters({
      ...filters,
      [filterName]: value
    });
  }
  const filteredSolutions = solutions_data.filter(solution => {
    if (selectedCategory === 'All' && !filters.rd && !filters.deployed) {
      return true;
    }
    if (selectedCategory !== 'All' && filters.rd && filters.deployed) {
      return solution.category === selectedCategory && (solution.filter === 'R&D' || solution.filter === 'Deployed');
    }
    if (selectedCategory !== 'All' && filters.rd) {
      return solution.category === selectedCategory && solution.filter === 'R&D';
    }
    if (selectedCategory !== 'All' && filters.deployed) {
      return solution.category === selectedCategory && solution.filter === 'Deployed';
    }
    if (filters.rd && filters.deployed) {
      return solution.filter === 'R&D' || solution.filter === 'Deployed';
    }
    if (filters.rd) {
      return solution.filter === 'R&D';
    }
    if (filters.deployed) {
      return solution.filter === 'Deployed';
    }
    return solution.category === selectedCategory;
  })
  return (
    <div>
      <div className="grid grid-cols-4 gap-2 px-8">
        {categories.map((category) => (
          <CategoryCard
            key={category.name}
            category={category}
            isSelected={selectedCategory === category.name}
            onClick={() => handleCategoryClick(category.name)}
          />
        ))}
      </div>
      <div className="flex align-center justify-center mt-6">
        <FilterCard filters={filters} onFilterChange={handleFilterChange} />
      </div>
      <div>
        <SolutionGrid solutions={filteredSolutions} />
      </div>
    </div>
  )
}

export default Solutions