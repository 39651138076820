import * as React from 'react';
import ImageBox from '../../../../../Shared/Components/ImageBox/ImageBox';
import { disease_data, after_report_map } from '../../../../../Constants/retino-constants';
import { getImages, getModelResults } from '../../../../../Actions/global';
import { useNavigate } from 'react-router-dom';
import { BASE_ROUTE } from '../../../../../../env';
import LoadingResult from '../../../../../Shared/Components/LoadingResult/LoadingResult';
import InternalDemoWrapper from '../../../../../Shared/Components/InternalDemoWrapper/InternalDemoWrapper';

const DiabeticRetinopathyDemo = () => {
    let navigate = useNavigate();
    const defaultImage = "/Assets/Images/default-image.svg";
    const [flag, setFlag] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [image, setImage] = React.useState(defaultImage);
    const [imageData, setImageData] = React.useState();
    const [footText, setFootText] = React.useState("No Image Selected");
    const [borderColor, setBorderColor] = React.useState("border-gray-300");
    const [footerBackgroudColor, setFooterBackgroundColor] = React.useState("bg-gray-300")
    const [referable, setReferable] = React.useState(false);
    const [nonReferable, setNonReferable] = React.useState(false);
    const [text, setText] = React.useState("select a picture");
    const matrix = disease_data.map((disease, index) => {
        return (
            <div key={index} className={`text-md text-center border-2 rounded px-2 py-3 ${flag.includes(disease.code) ? (disease.code === "No Diabetic Retinopathy") ? 'bg-green-500 font-medium text-white' : 'bg-red-500 font-medium text-white' : 'bg-gray-200 font-light'}`}>
                {disease.level}
            </div>
        )
    })

    React.useEffect(() => {
        getImagesData();
    }, []);

    React.useEffect(() => {
        setFootText("No Image Selected");
        setBorderColor("border-gray-300");
        setFooterBackgroundColor("bg-gray-300");
        setFlag(arr => []);
        setNonReferable(false);
        setReferable(false);
        getResults();
    }, [image]);

    async function getImagesData() {
        let data = await getImages("OPHTHA");
        if (data) {
            setImageData(data);
        }
        else {
            navigate(-1);
        }
    }

    async function getResults() {
        if (image === defaultImage) {
            return;
        }
        setReferable(false);
        setNonReferable(false);
        let data = await getModelResults(text, "OPHTHA", image)
        if (data) {
            setIsLoading(false);
            setFlag(arr => [data['predicted_class_name']]);
            if (data['predicted_class_name'] === 'No Diabetic Retinopathy') {
                setNonReferable(true);
                setFootText("Diabetic Retinopathy Undetected");
                setBorderColor("border-green-500");
                setFooterBackgroundColor("bg-green-500");
            } else {
                setReferable(true);
                setFootText("Diabetic Retinopathy Detected");
                setBorderColor("border-red-500");
                setFooterBackgroundColor("bg-red-500");
            }
        }
        else {
            navigate(-1);
        }
    }

    function handleClick() {
        setFootText("No Image Selected");
        setBorderColor("border-gray-300");
        setFooterBackgroundColor("bg-gray-300");
        setFlag(arr => []);
        setNonReferable(false);
        setReferable(false);
    }

    return (
        <div>
            <InternalDemoWrapper project={'Diabetic Retinopathy'} isLoading={isLoading} title={"An AI based solution to detect the presence of Diabetic Retinopathy (DR)."} backPath={-1} imageList={imageData} selectedImage={image} footText={footText} borderColor={borderColor} footerBackgroudColor={footerBackgroudColor} flag={flag} referable={referable} nonReferable={nonReferable} after_report_map={after_report_map} matrix={matrix} setImage={setImage} setText={setText} setIsLoading={setIsLoading} handleClick={handleClick} />
        </div>
    )
}
export default DiabeticRetinopathyDemo;
