import React from "react";
import "./ProblemCard.css";

const ProblemCard = ({backgroundImage, ratio, problem}) => {
  return (
    <div className="problem_card_outer w-[25vw] h-[30vh] rounded-2xl">
        <div
            className="problem_card w-[100%] h-[100%] rounded-2xl relative bg-cover bg-no-repeat bg-center"
            style={{backgroundImage: `linear-gradient(0deg, rgba(0 0 0 / 50%), rgba(0 0 0 / 50%)), url(${backgroundImage})`}}
        >
          <img src={'/Assets/Images/Problem_Vector.svg'} alt="Problem Vector" className="absolute top-[3vh] left-[1vw] opacity-[67]" style={{width: "3vw", height: "5vh"}}/>
          <p className="px-[1vw] pt-[12vh] pb-[1vh] text-[#E8F1FF] problem_word">Problem</p>
          <div className="pl-[1vw] pb-[1vh] text-[#DEC9C9] ratio_text">{ratio}</div>
          <div className="px-[1vw] text-[#FFFFFF] problem_text">
            {Array.isArray(problem)
              ? problem.map((line, index) => (
                  <div key={index}>{line}</div>
                ))
            : problem}
          </div>
        </div>
    </div>
  )
}

export default ProblemCard