import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import "./GoBackButton.css";

const GoBackButton = ({path}) => {
    console.log(path);
    let navigate = useNavigate();
    const handleBackClick = (path) => {
        navigate(path);
      };

    return (
        <div style={{zIndex: 99999}} onClick={() => handleBackClick(path)}>
            <div className='back-button-class w-[2.4rem] h-[2.4rem] rounded-lg p-3 cursor-pointer flex align-center justify-center '>
                <img src={'/Assets/Images/arrow_back.svg'} />
            </div>
        </div>
    )
}

export default GoBackButton;