import React from "react";
import "./SolutionCard.css";

const SolutionCard = ({solution, handleNavigate}) => {
    let url = solution.icon;
  return (
    <div className="flex gap-5 cursor-pointer items-center h-[6rem] w-full" onClick={() => handleNavigate(solution.path)} style={{maxHeight: "13vh", border: "1px solid #EDEDED", borderRadius: "10px",  boxShadow: "5px 5px 10px #EDEDED"}}>
        <div className="solution_image w-1/4 flex justify-center items-center rounded" style={{backgroundColor: solution.backgroundColor}}>
            <img src={url} style={{width: "80%", height: "100%"}} />
        </div>
        <div className="w-2/3 text-center">
            <div className="solution_name">{solution.name}</div>
            {/* <div className="solution_desc">{solution.description}</div> */}
        </div>
    </div>
  )
}

export default SolutionCard