import React from "react";
import "./ImpactCard.css";
import ContentCard from "../ContentCard/ContentCard";
import InformationCard from "../InformationCard/InformationCard";
import SdgCard from "../SdgCard/SdgCard";

const ImpactCard = ({information, content, sdgContent}) => {
  return (
    <div className="w-[32vw] h-full bg-[#E8EDF5] rounded-2xl">
        {information ?
        (
            <div>
                <InformationCard information={information}/>
            </div>)
        : null}
        <div className="flex flex-col  space-y-4 px-5 rounded-xl mt-[4vh]">
          {content.map((c, index) => (
            <ContentCard key={index} content={c.content} image_url={c.image} />
          ))}
        </div>
        <div className="px-5 py-4">
          <SdgCard sdgContent={sdgContent}/>
        </div>
    </div>
  )
}

export default ImpactCard