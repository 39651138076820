// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-gradient {
    font-weight: 500;
    background: radial-gradient(circle, #b66a94, #6788a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/Core/Components/KnowAI/KnowAI.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,qDAAqD;IACrD,6BAA6B;IAC7B,oCAAoC;AACxC","sourcesContent":[".text-gradient {\n    font-weight: 500;\n    background: radial-gradient(circle, #b66a94, #6788a8);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
