export let image_data = [
    {
        "id": 1,
        "tag": "Image_1",
        "url": "/Assets/Images/g-20.svg"
    },
    {
        "id": 2,
        "tag": "Image_2",
        "url": "/Assets/Images/g-20.svg"
    },
    {
        "id": 3,
        "tag": "Image_3",
        "url": "/Assets/Images/g-20.svg"
    }
    ,
    {
        "id": 4,
        "tag": "Image_3",
        "url": "/Assets/Images/g-20.svg"
    }
    ,
    {
        "id": 5,
        "tag": "Image_3",
        "url": "/Assets/Images/g-20.svg"
    }
]

export let disease_data = [
    {
        "id": 1,
        "code": "No Diabetic Retinopathy",
        "level": "No DR"
    },
    {
        "id": 2,
        "code": "Mild Diabetic Retinopathy",
        "level": "Mild DR"
    },
    {
        "id": 3,
        "code": "Moderate Diabetic Retinopathy",
        "level": "Moderate DR"
    },
    {
        "id": 4,
        "code": "Severe Diabetic Retinopathy",
        "level": "Severe DR"
    },
    {
        "id": 5,
        "code": "Proliferative Diabetic Retinopathy",
        "level": "Proliferative Retinopathy"
    }
]

export const after_report_map = {
    "No Diabetic Retinopathy" : "No referral required and ask for a follow up visit after a year and suggest him/her to monitor his/her sugar level.",
    "Mild Diabetic Retinopathy": "Suggest the patients to visit an ophthalmologist as soon as possible, do not delay this more than 6 months.",
    "Moderate Diabetic Retinopathy": "Suggest the patients to visit an ophthalmologist as soon as possible, do not delay this more than 2-3 months.",
    "Severe Diabetic Retinopathy": "Suggest the patients to visit an ophthalmologist immediately, do not delay this more than 2 months.",
    "Proliferative Diabetic Retinopathy": "Suggest the patients to visit a tertiary care center / ophthalmologist immediately, treatment is required within 4 weeks."
}