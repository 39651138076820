import React from "react";
import "./SolutionGrid.css";
import SolutionCard from "../SolutionCard/SolutionCard";
import { useNavigate } from "react-router-dom";

const SolutionGrid = ({solutions}) => {
  let navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
  }
  return (
    <div
        className="grid grid-cols-3 gap-6 p-4 overflow-y-auto mt-5"
        style={{ scrollBehavior: 'smooth', height: "58vh"}}
    >
        {solutions.map((solution) => (
            <div key={solution.id} className="flex items-center p-2">
            <SolutionCard solution={solution} handleNavigate={handleNavigate}/>
            </div>
        ))}
    </div>
  )
}

export default SolutionGrid;