export let opd = {
    "problem": "Suboptimal recommendations lead to delayed medical care delivery",
    "problem-image": "/Assets/Images/opd_problem.png",
    "model_heading": "Solution: OPD Recommendations",
    "model_question": "An AI solution that will provide OPD recommendations",
    "model_answer": "The solution will leverage cheif complaints from SIPF to provide accurate OPD recommendations",
    "content_array": [
        {"content" : "Timely diagnosis by directing patients to concerned OPD", "image": "/Assets/Images/tbchest_Content1.svg"},
        {"content" : "Prevent overcrowding at OPD counter", "image": "/Assets/Images/tbvul_Content2.svg"},
    ],
    "information": {
        "heading": "impact",
    },
    "timeline_information": [
        {"information": "Patient records chief complaints in SIPF", "image": '/Assets/Images/tbvulImage4.svg'},
        {"information": "AI analyzes complaints & recommends OPD", "image": '/Assets/Images/opdImage2.svg'},
        {"information": "Patient will be directed to the concerned OPD", "image": '/Assets/Images/RetinalImage2.svg'},
    ],
    "sdgContent": {
        "image": "/Assets/Images/cdss_sdg.svg",
        "heading": "Contributes to SDG 3.8:",
        "content": "Universal Health Coverage"
    },
    "nextSolution": "/health/solutions/sipf",
    "demo": false
}