import React from "react";
import NavBar from "../../NavBar/NavBar.js";
import ProblemCard from "../../../../Shared/Components/ProblemCard/ProblemCard.js";
import ModelCard from "../../../../Shared/Components/ModelCard/ModelCard.js";
import ImpactCard from "../../../../Shared/Components/ImpactCard/ImpactCard.js";
import TimelineCardWrapper from "../../../../Shared/Components/TimelineCardWrapper/TimelineCardWrapper.js";
import "./Opd.css";
import { opd } from "../../../../Constants/Solutions/opd-constants.js";

const Opd = () => {
  return (
    <div>
        <div>
            <NavBar demo={opd.demo}/>
        </div>
        <div className="grid grid-cols-3 py-[0.8rem] px-6 gap-2">
            <div className="col-span-2">
                <div className="flex gap-6">
                    <ProblemCard backgroundImage={opd["problem-image"]} problem={opd.problem}/>
                    <ModelCard model_heading={opd.model_heading} model_question={opd.model_question} model_answer={opd.model_answer}/>
                </div>
                <div className="flex flex-col pr-4 mt-6">
                    <TimelineCardWrapper information={opd.timeline_information}/>
                </div>
            </div>
            <div className="col-span-1">
                <div className="h-full">
                    <ImpactCard information={opd.information} content={opd.content_array} sdgContent={opd.sdgContent} />
                    <div className="text-[#838385] source_text mt-1">Source: National family & health Survey in India, 2021</div>
                </div>
            </div>
        </div>
    </div>
  )
};

export default Opd;