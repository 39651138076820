export const knowAI = {
    "main_page": {
        "title": "AI for Everyone: Basics Unboxed",
        "sub_title": {
            "text_1": "Let's dive into the ",
            "text_2": "world of AI!"
        },
        "main_cards": [
            {
                "path": "classification",
                "background": "#FCF5EB",
                "shadow": "#9C6617",
                "image": "classificationIMG.png",
                "title": "Classification",
                "description": "It involves grouping objects based on similarities or differences"
            },
            {
                "path": "llm",
                "background": "#e7f8ff",
                "shadow": "#265163",
                "image": "llm-ill.svg",
                "title": "Large Language Models",
                "description": "It analyses vast amount of data to understand and produce text similar to human language"
            },
            {
                "path": "decision-tree",
                "background": "#f5d7f1",
                "shadow": "#4a1e44",
                "image": "tree_decision.svg",
                "title": "Decision Tree",
                "description": "It's a flowchart that guides decisions based on responses to questions"
            }
        ]
    }
}