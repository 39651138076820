import React from "react";
import "./CategoryCard.css";

const CategoryCard = ({category, isSelected, onClick}) => {
    const cardStyles = isSelected
    ? "border-4 border-[#2D8259] bg-gradient-to-br from-[#E4FFF2] to-[#E6FFF3] rounded-3xl"
    : "border border-[#F5FFF5] rounded-3xl bg-[#F7FAF9]";

    const nameStyles = isSelected
    ? "text-[#2D8259] font-[900]"
    : "text-[#2D8259] font-[500] opacity-90";

    const infoStyles = isSelected
    ? "text-[#233D66] font-[500] text-[1.9vh]"
    : "text-[#233B11] font-[400] opacity-90";

  return (
    <div
        className={`category_card mt-3 ${cardStyles} flex flex-col justify-evenly`}
        onClick={onClick}
    >
        <div className={`category_name text-center ${nameStyles}`}>{category.name}</div>
        <div className={`category_info text-center px-3 ${infoStyles}`}>{category.information}</div>
    </div>
  )
}

export default CategoryCard;