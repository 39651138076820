import * as React from 'react';
import GoBackButton from '../../../../../Shared/Components/GoBackButton/GoBackButton';
import { LLM_GAME_URL } from '../../../../../../env';

const LLM = () => {
    return (
        <div className='relative h-screen w-screen'>
            <div className='fixed m-6 px-2'>
                <GoBackButton path={"/health/knowai"} />
            </div>
            <iframe className='h-[100%] w-[100%]' src={LLM_GAME_URL} sandbox='allow-scripts' />
        </div>
    )
}

export default LLM;