// API URLS
export const SERVER_URL = process.env.REACT_APP_API_ENDPOINT;
export const USERNAME = process.env.REACT_APP_USERNAME;
export const PASSWORD = process.env.REACT_APP_PASSWORD;
export const LOGIN_URL = SERVER_URL + 'api/login';
export const GET_URLS = SERVER_URL + "/api/get_urls";
export const POST_URLS = SERVER_URL + "/api/uploaded";
// IFRAME URLS
export const CLASSIFICATION_GAME_URL = process.env.REACT_APP_CLASSIFICATION_GAME_ENDPOINT;
export const DECISION_GAME_URL = process.env.REACT_APP_DECISION_GAME_ENDPOINT;
export const LLM_GAME_URL = process.env.REACT_APP_LLM_GAME_ENDPOINT;
// APP ROUTES
export const BASE_ROUTE = process.env.REACT_APP_BASE_ROUTE