import React from "react";
import NavBar from "../../NavBar/NavBar.js";
import ProblemCard from "../../../../Shared/Components/ProblemCard/ProblemCard.js";
import ModelCard from "../../../../Shared/Components/ModelCard/ModelCard.js";
import ImpactCard from "../../../../Shared/Components/ImpactCard/ImpactCard.js";
import TimelineCardWrapper from "../../../../Shared/Components/TimelineCardWrapper/TimelineCardWrapper.js";
import "./NewBornAnthro.css";
import { newborn } from "../../../../Constants/Solutions/newborn-constants.js";

const NewBornAnthro = () => {
  return (
    <div>
        <div>
            <NavBar path={newborn.nextSolution} demo={newborn.demo}  demoType={"External"} demoUrl={newborn.demourl}/>
        </div>
        <div className="grid grid-cols-3 py-[0.8rem] px-6 gap-2">
            <div className="col-span-2">
                <div className="flex gap-6">
                    <ProblemCard backgroundImage={newborn["problem-image"]} problem={newborn.problem}/>
                    <ModelCard model_heading={newborn.model_heading} model_question={newborn.model_question} model_answer={newborn.model_answer}/>
                </div>
                <div className="flex flex-col pr-4 mt-6">
                    <TimelineCardWrapper information={newborn.timeline_information}/>
                </div>
            </div>
            <div className="col-span-1">
                <div className="h-full">
                    <ImpactCard information={newborn.information} content={newborn.content_array} sdgContent={newborn.sdgContent}/>
                    <div className="text-[#838385] source_text mt-1">Source: National family & health Survey in India, 2021</div>
                </div>
            </div>
        </div>
    </div>
  )
};

export default NewBornAnthro;