// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    background: linear-gradient(0deg, rgba(144, 144, 144, 0.1), rgba(144, 144, 144, 0.1)), linear-gradient(0deg, #FCFCFC, #FCFCFC);
    border-bottom: 1px solid #9090901A
}

.sol_text {
    font-size: 1.75vh;
    font-weight: 400;
    font-family: "Lato";
}
`, "",{"version":3,"sources":["webpack://./src/app/Core/Components/NavBar/NavBar.css"],"names":[],"mappings":"AAAA;IACI,8HAA8H;IAC9H;AACJ;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".navbar {\n    background: linear-gradient(0deg, rgba(144, 144, 144, 0.1), rgba(144, 144, 144, 0.1)), linear-gradient(0deg, #FCFCFC, #FCFCFC);\n    border-bottom: 1px solid #9090901A\n}\n\n.sol_text {\n    font-size: 1.75vh;\n    font-weight: 400;\n    font-family: \"Lato\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
