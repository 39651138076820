export let dermatology = {
    "problem": "In India there is 1 dermatologist for 0.13M patients",
    "problem-image": "/Assets/Images/Derma_Problem.png",
    "model_heading": "Solution: AI powered screening for skin conditions",
    "model_question": "An AI solution for screening 10 skin conditions",
    "model_answer": "AI-driven screening tool for HWCs, PHCs, and CHCs to ensure appropriate treatment and care",
    "content_array": [
        {"content" : "Equipping doctors with AI tools to curb community spread", "image": "/Assets/Images/Reti_Content1.svg"},
        {"content" : "Reducing consultation time required for diagnosis", "image": "/Assets/Images/Derma_Content2.svg"},
        {"content" : "Savings costs via accessible and accurate diagnoses", "image": "/Assets/Images/Derma_Content3.svg"}
    ],
    "timeline_information": [
        {"information": "Healthcare worker takes photo of visible symptoms on the skin", "image": '/Assets/Images/Derma_Image1.svg'},
        {"information": "HCW collects chief complaints via SIPF", "image": '/Assets/Images/Derma_Image2.svg'},
        {"information": "AI model analyses & offers diagnosis recommendation", "image": '/Assets/Images/RetinalImage2.svg'},
        {"information": "Dermatologist validates AI diagnosis & recommends treatment", "image": '/Assets/Images/Derma_Image4.svg'}
    ],
    "information": {
        "heading": "Impact",
        "number1": "81.2%",
        "numbertext": "accuracy",
        "middletext": "and",
        "number2": "82.1%",
        "imagetext": "sensitivity"
    },
    "sdgContent": {
        "image": "/Assets/Images/reti_sdg.svg",
        "heading": "Contributes to SDG 3.4:",
        "content": "Noncommunicable diseases"
    },
    "nextSolution": "/health/solutions/mediasurveillance",
    // "demo": true
}