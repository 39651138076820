import React from "react";
import NavBar from "../../NavBar/NavBar.js";
import ProblemCard from "../../../../Shared/Components/ProblemCard/ProblemCard.js";
import ModelCard from "../../../../Shared/Components/ModelCard/ModelCard.js";
import ImpactCard from "../../../../Shared/Components/ImpactCard/ImpactCard.js";
import TimelineCardWrapper from "../../../../Shared/Components/TimelineCardWrapper/TimelineCardWrapper.js";
import "./TBChest.css";
import { tbchestxray } from "../../../../Constants/Solutions/tbchest-constants.js";

const TBChest = () => {
  return (
    <div>
        <div>
            <NavBar path={tbchestxray.nextSolution} demo={tbchestxray.demo} demoType={"Internal"}/>
        </div>
        <div className="grid grid-cols-3 py-[0.8rem] px-6 gap-2">
            <div className="col-span-2">
                <div className="flex gap-6">
                    <ProblemCard backgroundImage={tbchestxray["problem-image"]} problem={tbchestxray.problem}/>
                    <ModelCard model_heading={tbchestxray.model_heading} model_question={tbchestxray.model_question} model_answer={tbchestxray.model_answer}/>
                </div>
                <div className="flex flex-col pr-4 mt-6">
                    <TimelineCardWrapper information={tbchestxray.timeline_information}/>
                    <div className="opacity-80 text-[#9DA0A4] col_text mt-1" >Collaboration with AIIMS Delhi and Ministry of Health and Family Welfare</div>
                </div>
            </div>
            <div className="col-span-1">
                <div className="h-full">
                    <ImpactCard information={tbchestxray.information} content={tbchestxray.content_array} sdgContent={tbchestxray.sdgContent}/>
                    <div className="text-[#838385] source_text mt-1">Source: Apollo, 2019	(Adrian P. Brady, 2016)</div>
                </div>
            </div>
        </div>
    </div>
  )
};

export default TBChest;