import React from "react";
import "./SdgCard.css";

const SdgCard = ({sdgContent}) => {
  return (
    <div className="sdg_card flex gap-6 p-4 justify-start items-center">
        <img src={sdgContent.image} style={{width: "3.7vw", height: "6.1vh"}}/>
        <div className='flex flex-col'>
            <div className="content_heading text-[#FFFFFF]">{sdgContent.heading}</div>
            <div className="content_content text-[#FFFFFF]">{sdgContent.content}</div>
        </div>
    </div>
  )
}

export default SdgCard