export let vulnerability = {
    "problem": "1.8 out of every 2.8 TB cases in the community remain unreported",
    "problem-image": "/Assets/Images/tbvul_problem.png",
    "model_heading": "Solution: vulnerability mapping for tb",
    "model_question": "An AI model to identify and monitor TB-vulnerable regions",
    "model_answer": "AI solution to predict geographical areas vulnerable to TB, leveraging geospatial and programmatic datasets",
    "content_array": [
        {"content" : "Enable targeted case-finding in high-risk regions", "image": "/Assets/Images/tbvul_Content1.svg"},
        {"content" : "Increase case notifications and reduce missing cases", "image": "/Assets/Images/tbvul_Content2.svg"},
        { "content": "8,000 additional cases detected during pan-India case-finding programs", "image": "/Assets/Images/tbvul_Content3.svg"}
    ],
    "information": {
        "heading": "Potential impact",
    },
    "timeline_information": [
        {"information": "AI learns from geospatial features and case counts", "image": '/Assets/Images/tbvulImage1.svg'},
        {"information": "Accuracy tested, if satisfactory, AI predicts on target", "image": '/Assets/Images/RetinalImage2.svg'},
        {"information": "DTO selects villages based on accuracy and local intelligence", "image": '/Assets/Images/tblpaImage3.svg'},
        {"information": "ACF activity conducted in target areas by ASHAs", "image": '/Assets/Images/tbvulImage4.svg'}
    ],
    "sdgContent": {
        "image": "/Assets/Images/media_sdg.svg",
        "heading": "Contributes to SDG 3.3:",
        "content": "Infectious diseases"
    },
    "nextSolution": "/health/solutions/newbornanthro",
    "demo": false
}