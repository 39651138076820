import React from "react";
import NavBar from "../../NavBar/NavBar.js";
import ProblemCard from "../../../../Shared/Components/ProblemCard/ProblemCard.js";
import ModelCard from "../../../../Shared/Components/ModelCard/ModelCard.js";
import ImpactCard from "../../../../Shared/Components/ImpactCard/ImpactCard.js";
import TimelineCardWrapper from "../../../../Shared/Components/TimelineCardWrapper/TimelineCardWrapper.js";
import "./Asha.css";
import { asha } from "../../../../Constants/Solutions/asha-constants.js";

const Asha = () => {
  return (
    <div>
        <div>
            <NavBar path={asha.nextSolution} demo={asha.demo} demoType={"External"} demoUrl={asha.demoUrl}/>
        </div>
        <div className="grid grid-cols-3 py-[0.8rem] px-6 gap-2">
            <div className="col-span-2">
                <div className="flex gap-6">
                    <ProblemCard backgroundImage={asha["problem-image"]} problem={asha.problem}/>
                    <ModelCard model_heading={asha.model_heading} model_question={asha.model_question} model_answer={asha.model_answer}/>
                </div>
                <div className="flex flex-col pr-4 mt-6">
                    <TimelineCardWrapper information={asha.timeline_information}/>
                    <div></div>
                </div>
            </div>
            <div className="col-span-1">
                <div className="h-full">
                    <ImpactCard information={asha.information} content={asha.content_array} sdgContent={asha.sdgContent} />
                    <div className="text-[#838385] mt-2 source_text px-1">
                    Source: National Tuberculosis Prevalence Survey in India, 2021
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
};

export default Asha;