// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    box-shadow: 0px 4px 4px 0px #00000014;
    border-radius: 10px;
    height: 27vh;
    width: 11vw;
    margin-bottom: 2vh;
    background: #F9FAFC;
}

.card.highlighted {
    box-shadow: 0px 0px 12px -1px #3F6BB1;
    background: #EEF2F9;
}

.card_text {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 1.7vh;
    line-height: 2.2vh;
    margin-top: 1rem;
}

.card_text.non_highlighted {
    opacity: 67%;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/app/Shared/Components/TimelineCard/TimelineCard.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".card {\n    box-shadow: 0px 4px 4px 0px #00000014;\n    border-radius: 10px;\n    height: 27vh;\n    width: 11vw;\n    margin-bottom: 2vh;\n    background: #F9FAFC;\n}\n\n.card.highlighted {\n    box-shadow: 0px 0px 12px -1px #3F6BB1;\n    background: #EEF2F9;\n}\n\n.card_text {\n    font-family: \"Roboto\";\n    font-weight: 600;\n    font-size: 1.7vh;\n    line-height: 2.2vh;\n    margin-top: 1rem;\n}\n\n.card_text.non_highlighted {\n    opacity: 67%;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
