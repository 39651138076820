import * as React from 'react';
import GoBackButton from "../GoBackButton/GoBackButton";
import "./InternalDemoWrapper.css";
import ImageBox from '../ImageBox/ImageBox';
import DiabeticRetinopathyMatrix from '../DemoMatrix/DiabeticRetinopathy/DiabeticRetinopathyMatrix';
import DermatologyMatrix from '../DemoMatrix/Dermatology/DermatologyMatrix';
import LoadingResult from '../LoadingResult/LoadingResult';
import TBChestMatrix from '../DemoMatrix/TBChest/TBChestMatrix';

const InternalDemoWrapper = ({ project, isLoading, title, backPath, imageList, selectedImage, footText, borderColor, footerBackgroudColor, matrixContainer, flag, referable, nonReferable, after_report_map, consult, matrix, setImage = () => { }, setText = () => { }, setIsLoading = () => { }, handleClick = () => { } }) => {
    return (
        <div className='realtive h-screen w-screen flex flex-col'>
            {isLoading && <LoadingResult />}
            <div className='absolute m-6 px-6' style={{zIndex: 999}}>
                <GoBackButton path={backPath} />
            </div>
            <div className='absolute w-[100%] p-4 flex justify-between items-center'>
                <div className='w-[25%]'>
                </div>
                <div className='w-[75%] my-4 text-center'>
                    {title}
                </div>
            </div>
            <div className='mt-20 px-6'>
                <div className='grid grid-cols-5'>
                    <div className='col-span-2'>
                        <ImageBox data={imageList}
                            color={"bg-blue-100"}
                            setImage={setImage}
                            footText={footText}
                            borderColor={borderColor}
                            footerBackgroudColor={footerBackgroudColor}
                            image={selectedImage}
                            setText={setText}
                            setIsLoading={setIsLoading}
                            handleClick={handleClick} />
                    </div>
                    <div className='col-span-3 flex flex-col pl-8'>
                            {
                                project === 'Diabetic Retinopathy' && <DiabeticRetinopathyMatrix flag={flag} referable={referable} nonReferable={nonReferable} after_report_map={after_report_map} matrix={matrix} />
                            }
                            {
                                project === 'Dermatology' && <DermatologyMatrix flag={flag} matrix={matrix} consult={consult} />
                            }
                            {
                                project === 'Teleradiology' && <TBChestMatrix flag={flag} referable={referable} nonReferable={nonReferable} after_report_map={after_report_map} matrix={matrix} />
                            }
                    </div>
                </div>

            </div>
        </div>
    )
}

export default InternalDemoWrapper