// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content_card {
    box-shadow: 0px 0px 0px 0px rgba(4, 63, 99, 0.05), -1px 1px 2px 0px rgba(4, 63, 99, 0.05), -3px 2px 3px 0px rgba(4, 63, 99, 0.04), -6px 5px 4px 0px rgba(4, 63, 99, 0.03), -10px 8px 5px 0px rgba(4, 63, 99, 0.01), -16px 13px 6px 0px rgba(4, 63, 99, 0.00);
}

.content_text {
    font-family: "Roboto";
    font-size: 1.9vh;
    font-weight: 400;
    line-height: 2vh;
    letter-spacing: 0em;
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/Shared/Components/ContentCard/ContentCard.css"],"names":[],"mappings":"AAAA;IACI,4PAA4P;AAChQ;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".content_card {\n    box-shadow: 0px 0px 0px 0px rgba(4, 63, 99, 0.05), -1px 1px 2px 0px rgba(4, 63, 99, 0.05), -3px 2px 3px 0px rgba(4, 63, 99, 0.04), -6px 5px 4px 0px rgba(4, 63, 99, 0.03), -10px 8px 5px 0px rgba(4, 63, 99, 0.01), -16px 13px 6px 0px rgba(4, 63, 99, 0.00);\n}\n\n.content_text {\n    font-family: \"Roboto\";\n    font-size: 1.9vh;\n    font-weight: 400;\n    line-height: 2vh;\n    letter-spacing: 0em;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
