export let image_data = [
    {
        "index": 1,
        "name": "Image-1",
        "src": "/Assets/Images/g-20.svg"
    },
    {
        "index": 2,
        "name": "Image-2",
        "src": "https://g20-demo-data.s3.ap-south-1.amazonaws.com/g20_ophtha/1259_left.jpeg?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEYaCmFwLXNvdXRoLTEiRzBFAiAYcR9B6gguknDY3IUmoWXLM4q%2FodIN93C48wH3UfHAxgIhAKNPlQaWCOKo9b6Ty%2Fay94UmlYTSm8PwMw8%2FEneXitzzKpIDCJ%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMNjc0ODAxMTM1MTI5IgxTU2s%2Bdi9smphUpOUq5gKyQZfUWjCno2wS9qz3cSonB0wE0A65dYVI69PcmzKVEJj3ZddYz%2BV1nE5tdGTu51QhCTl9x2NjgHzwPFOfhcGRAE5EZMSyhNmrBnXrFQtounC%2Bs1zWcurtUACiRP0kteLeJmhTuKpXhpA2mQPFHQ49oaaPEGVdAxlr42CfJHFgOfKHZ1VwtNWbV9vnn0jE9EpPxE5iV13cb23rKj0%2F0A8k3uHG6PeFRLTPi5RKzJCkwAFDziqogQCuptTpw5EP14qN3dl5pHxl6IlK%2F%2FoklL6JiAnznxxXGVozPQd5hUAjFByh9PIm17NsEzQO4rAIolE%2Fz1FEI0BSAPwQeAdyUlPNk28d3PcreT0BnnTdNZZ6TgbPIQLNRcrvym%2Bpx62CGw6nFlYy%2BOyzItSrMQNli2ChAIs81ytv%2BJHDwQEPHLR%2Bs8aYzzaSlHgd%2Bp4TvLF8UQGRAl9wNtVGfR3%2FgGxtqf6tMr7RlBRvMOKe%2BZ0GOrMCX9v8WJ2OQ8Y8ekiX%2BDdAb4aOcKjrEF5kuupSCXUo4LhK22%2BLRp%2FAwAh6mbF8EHj07o5ho5UpxT5SN1TfKVo86z4ad7rh5Ofz22R%2BBWk2y%2BbqyvZORM2BNb9BynmphiWIgkWUBsURiNeHx6halHgZgsFdzywowMHkIQhCrxfR8SE4%2Fa8BmEFNpPaB8d4qlTvpRtGIRJGZuBDfXm%2Btttgvly4knweZ5A0HTM4PzXXFouGlAOaZA9S8vZM1Jc9Ruvcdt8HYg4V03VpNMcQ4%2BjxZMQLZKTCmBBn56fK%2Fs5CUPFscvJ3As%2BX%2B8MvnfXaYu7086%2FuNmoAKVDpusymuQLHRE%2FhMNJ90J9dbQ08GwUrE6pQ2jAEcb807BfhmdYfP9Xfg4Q7DkmewWLsQwJfQYuDp60z55g%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230111T065700Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Credential=ASIAZ2HKIFYMZEDPBSHL%2F20230111%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=c146afec1c3c4c99d597f5aef7c0dc5b2e614e7c557556fa44f3ef84ea189d2a"
    },
    {
        "index": 3,
        "name": "Image-3",
        "src": "/Assets/Images/bullets-1.png"
    }
]

export let disease_data = [
    {
        "index": 1,
        "code": "Infiltration/Consolidation",
        "name": "Infiltration/Consolidation"
    },
    {
        "index": 2,
        "code": "Edema",
        "name": "Edema"
    },
    {
        "index": 3,
        "code": "Emphysema",
        "name": "Emphysema"
    },
    {
        "index": 4,
        "code": "Atelectasis",
        "name": "Atelectasis"
    },
    {
        "index": 5,
        "code": "Nodule/Mass",
        "name": "Nodule/Mass"
    },
    {
        "index": 6,
        "code": "Pneumothorax",
        "name": "Pneumothorax"
    },
    {
        "index": 7,
        "code": "Fibrosis",
        "name": "Fibrosis"
    },
    {
        "index": 8,
        "code": "Cardiomegaly",
        "name": "Cardiomegaly"
    },
    {
        "index": 9,
        "code": "Hernia",
        "name": "Hernia"
    },
    {
        "index": 10,
        "code": "Effusion",
        "name": "Effusion"
    },
    {
        "index": 11,
        "code": "Pleural_Thickening",
        "name": "Pleural Thickening"
    },
    {
        "index": 12,
        "code": "Lung Opacity",
        "name": "Lung Opacity"
    },
    {
        "index": 13,
        "code": "Fracture",
        "name": "Fracture"
    },
    {
        "index": 14,
        "code": "Enlarged Cardiomediastinum",
        "name": "Enlarged Cardiomediastinum"
    },
    {
        "index": 15,
        "code": "Air_Trapping",
        "name": "Air Trapping"
    },
    {
        "index": 16,
        "code": "Support Devices",
        "name": "Support Devices"
    },
    {
        "index": 17,
        "code": "Pleural Other",
        "name": "Pleural Other (Not Predicted)"
    }
]