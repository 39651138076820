// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main-div {
    scroll-behavior: smooth;
    display: -ms-flexbox;
    font-family: monospace !important;
}

#main-div > * {
    flex: 1 1;
  }

.scrolling-wrapper {
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    
}

.scrolling-wrapper .item {
    display: inline-block;
    background: #edeeed;
  }

.start-button {
    background: #20618d;
    color: #f1f3f2;
    font-size: 1.7rem;
    text-align: center;
    border-radius: 10px;
}

.heading {
  font-weight: 500;
}

#welldone {
  display: inline-block;
  transform: scale(1);
  transition: all 100ms linear;
}
.booable{
  color: red;
  transform: scale(2);
  transition: all 100ms linear;
}`, "",{"version":3,"sources":["webpack://./src/app/Core/Components/KnowAI/Components/DecisionTree/DecisionTree.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IAEvB,oBAAoB;IACpB,iCAAiC;AACrC;;AAEA;IAGI,SAAO;EACT;;AAEF;IACI,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;;AAEjB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;EACrB;;AAEF;IACI,mBAAmB;IACnB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,4BAA4B;AAC9B;AACA;EACE,UAAU;EACV,mBAAmB;EACnB,4BAA4B;AAC9B","sourcesContent":["#main-div {\n    scroll-behavior: smooth;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    font-family: monospace !important;\n}\n\n#main-div > * {\n    -webkit-box-flex: 1;\n    -ms-flex: 1;\n    flex: 1;\n  }\n\n.scrolling-wrapper {\n    overflow-y: hidden;\n    white-space: nowrap;\n    display: flex;\n    \n}\n\n.scrolling-wrapper .item {\n    display: inline-block;\n    background: #edeeed;\n  }\n\n.start-button {\n    background: #20618d;\n    color: #f1f3f2;\n    font-size: 1.7rem;\n    text-align: center;\n    border-radius: 10px;\n}\n\n.heading {\n  font-weight: 500;\n}\n\n#welldone {\n  display: inline-block;\n  transform: scale(1);\n  transition: all 100ms linear;\n}\n.booable{\n  color: red;\n  transform: scale(2);\n  transition: all 100ms linear;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
