// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.domain-card {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    border-radius: 30px;
    border: 1px solid lightgray;
    box-shadow: 5px 5px 10px lightgray;
    cursor: pointer;
    transition: transform 1s;
    width: 29vw;
    background-repeat:no-repeat;
    background-size: cover;
    height: 35vh;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-size: contain;
    background-position: center;
    background-color: #FFF;
}

.domain-card:hover {
    transform: scale(1.02);
}`, "",{"version":3,"sources":["webpack://./src/app/Shared/Components/DomainCard/DomainCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,mBAAmB;IACnB,2BAA2B;IAC3B,kCAAkC;IAClC,eAAe;IACf,wBAAwB;IACxB,WAAW;IAEX,2BAA2B;IAC3B,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;IACpB,wBAAwB;IACxB,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".domain-card {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #FFF;\n    border-radius: 30px;\n    border: 1px solid lightgray;\n    box-shadow: 5px 5px 10px lightgray;\n    cursor: pointer;\n    transition: transform 1s;\n    width: 29vw;\n    height: 15vh;\n    background-repeat:no-repeat;\n    background-size: cover;\n    height: 35vh;\n    padding-top: 5rem;\n    padding-bottom: 5rem;\n    background-size: contain;\n    background-position: center;\n    background-color: #FFF;\n}\n\n.domain-card:hover {\n    transform: scale(1.02);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
