export let newborn = {
    "problem": "Neonatal mortality rate: 24.9 deaths per 1,000 live births",
    "problem-image": "/Assets/Images/newborn_Problem.png",
    "model_heading": "Solution: Newborn anthropometry",
    "model_question": "An AI solution to identify and monitor newborns at-risk",
    "model_answer": "AI-powered smartphone solution for weight estimation and other anthropometric measurements of newborns (0-42 days) to monitor growth and identify underweight babies",
    "content_array": [
        {"content" : "Improvement in neonatal health care delivery processes  ", "image": "/Assets/Images/newborn_Content1.svg"},
        {"content" : "Reduce errors in weight estimation of LBW by helping frontline workers", "image": "/Assets/Images/newborn_Content2.svg"},
    ],
    "information": {
        "heading": "Impact",
        "number1": "87%",
        "numbertext": "of predictions",
        "uppermiddletext": "with",
        "number2": "<10%",
        "imagetext": "error",
    },
    "timeline_information": [
        {"information": "HCW takes video of newborn using the Digital Tarazu app", "image": '/Assets/Images/newbornImage1.svg'},
        {"information": "AI model estimates newborn's weight + other measurements", "image": '/Assets/Images/RetinalImage2.svg'},
        {"information": "HCW makes recommendations based on results", "image": '/Assets/Images/newbornImage3.svg'},
        {"information": "Real-time low birthweight prevalence updates to databases", "image": '/Assets/Images/mediaImage3.svg'}
    ],
    "sdgContent": {
        "image": "/Assets/Images/newborn_sdg.svg",
        "heading": "Contributes to SDG 3.2:",
        "content": "Neonatal and child mortality"
    },
    "nextSolution": "/health/solutions/under6anthro",
    "demo": true,
    "demourl": "https://drive.google.com/file/d/14XKgK8rZgkkfMC4_uKY-OX8LEO_w4Mjm/view?resourcekey"
}