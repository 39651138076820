import React from "react";
import "./ModelCard.css";

const ModelCard = ({ model_heading, model_question, model_answer, showExpandButton, setExpandFlag = () => { } }) => {
  return (
    <div className="bg-[#12356D] w-[33vw] h-[30vh] rounded-2xl flex flex-col justify-between relative">
      <div className="mt-6 mx-6">
        <div className="model_heading text-[#E8F1FF] opacity-70">{model_heading}</div>
      </div>
      <div className="flex flex-col gap-6 pb-[4vh] mx-6">
        <div className="model_question text-[#FFFFFF]">{model_question}</div>
        <div className="model_answer text-[#FFFFFF]">{model_answer}</div>
      </div>
      {showExpandButton && <div className="absolute mx-4 mt-6 right-0 cursor-pointer" onClick={() => setExpandFlag(true)}>
        <img src={"/Assets/Images/expand-arrow.png"} style={{height: "1rem"}}/>
      </div>}
    </div>
  )
}

export default ModelCard