import React from "react";
import NavBar from "../../NavBar/NavBar.js";
import ProblemCard from "../../../../Shared/Components/ProblemCard/ProblemCard.js";
import ModelCard from "../../../../Shared/Components/ModelCard/ModelCard.js";
import ImpactCard from "../../../../Shared/Components/ImpactCard/ImpactCard.js";
import TimelineCardWrapper from "../../../../Shared/Components/TimelineCardWrapper/TimelineCardWrapper.js";
import "./TBLine.css";
import { tblpa } from "../../../../Constants/Solutions/tblpa-constants.js";

const TBLine = () => {
  return (
    <div>
        <div>
            <NavBar path={tblpa.nextSolution} demo={tblpa.demo} demoType={"External"}  demoUrl={tblpa.demourl} />
        </div>
        <div className="grid grid-cols-3 py-[0.8rem] px-6 gap-2">
            <div className="col-span-2">
                <div className="flex gap-6">
                    <ProblemCard backgroundImage={tblpa["problem-image"]} problem={tblpa.problem}/>
                    <ModelCard model_heading={tblpa.model_heading} model_question={tblpa.model_question} model_answer={tblpa.model_answer}/>
                </div>
                <div className="flex flex-col pr-4 mt-6">
                    <TimelineCardWrapper information={tblpa.timeline_information}/>
                </div>
            </div>
            <div className="col-span-1">
                <div className="h-full">
                    <ImpactCard information={tblpa.information} content={tblpa.content_array} sdgContent={tblpa.sdgContent}/>
                    <div className="text-[#838385] source_text mt-1">
                        Source: National Tuberculosis Prevalence Survey in India, 2021
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
};

export default TBLine;