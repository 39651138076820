import React from 'react'

function DropDownMenu({data, setText, color, image, setImage = () => { },  setIsLoading = () => { }}) {

    const options = data.map((option, index) => {
        return (
            <>
            {image !== option.url ? (
                <div key={index} className={`flex place-content-center w-full p-2 bg-white text-black hover:${color}`} onClick={() => {setText(option.tag); setImage(option.url); setIsLoading(true);}}>
                    <div>
                        <img  src={option.url} alt={option.tag} className='h-[350px]' />
                    </div>
                </div>
            ) : (
                <> </>
            )}
            </>
        )
    })
    return (
        <div className='absolute rounded-xl shadow-lg mt-2 w-1/3 h-2/4 overflow-y-scroll'>
            {options}
        </div>
    )
}

export default DropDownMenu