// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model_heading {
    font-family: "Inter";
    font-weight: 600;
    font-size: 2.1vh;
    line-height: 2.5vh;
    text-transform: uppercase;
    text-align: left;
}

.model_question {
    font-family: "Lato";
    font-weight: 800;
    font-size: 2.5vh;
    line-height: 3.25vh;
    letter-spacing: 0.5%;
    text-align: left;
}

.model_answer {
    font-family: "Lato";
    font-size: 1.9vh;
    line-height: 2.1vh;
    letter-spacing: 0.5%;
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/Shared/Components/ModelCard/ModelCard.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,oBAAoB;IACpB,gBAAgB;AACpB","sourcesContent":[".model_heading {\n    font-family: \"Inter\";\n    font-weight: 600;\n    font-size: 2.1vh;\n    line-height: 2.5vh;\n    text-transform: uppercase;\n    text-align: left;\n}\n\n.model_question {\n    font-family: \"Lato\";\n    font-weight: 800;\n    font-size: 2.5vh;\n    line-height: 3.25vh;\n    letter-spacing: 0.5%;\n    text-align: left;\n}\n\n.model_answer {\n    font-family: \"Lato\";\n    font-size: 1.9vh;\n    line-height: 2.1vh;\n    letter-spacing: 0.5%;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
