import * as React from 'react';

const KnowAIMainText = ({title, sub_title}) => {
    return (
        <>
            <div className='text-5xl font-[700] text-center'>{title}</div>
            <div id="sub_title" className='text-xl font-[400] text-center mt-6'>
                <div>
                    {sub_title["text_1"]}
                </div>
                <div className='text-gradient'>
                    {sub_title["text_2"]}
                </div>
            </div>
        </>
    )
}

export default KnowAIMainText;
