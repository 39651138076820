import * as React from 'react';
import { health } from '../../../Constants/health-constants';
import DomainCard from '../../../Shared/Components/DomainCard/DomainCard';
import { useNavigate } from 'react-router-dom';
import "./Health.css";
import ParticlesComponent from '../../../Shared/Components/Particles/ParticlesComponent';
import { login } from '../../../Actions/global';


const Health = ({}) => {
    let navigate = useNavigate();

    const routing = (path) => {
        navigate(path)
    };

    return (
        <div className='main-health h-screen'>
            <ParticlesComponent count={70} speed={3} color={"#4b9773"} background={"#FFFFFF"} />
            <div className='health-heading'>
                {health.heading1}
            </div>
            <div className='health-heading'>
                {health.heading2}
            </div>
            <div className='health-content w-[60vw]'>
                {health.content}
            </div>
            <div className='flex align-center justify-center w-full mt-10' style={{ height: "30vh" }}>
                <div className='grid grid-cols-2 gap-16'>
                    <div className='col-span-1'>
                        <DomainCard bgImage={'/Assets/Images/knowAI.png'} title={"AI for Everyone"} description={""} path={"/health/knowai"} clicked={routing} />
                    </div>
                    <div className='col-span-1'>
                        <DomainCard bgImage={'/Assets/Images/solutionDisplay.png'} title={"Solutions"} description={""} path={"/health/solutions"} clicked={routing} />
                    </div>
                </div>
            </div>
            {/* <div className='image-background' style={{width: "30vw", height: "45vh"}}>
                <img src={healthLanding} alt='E-health AI'/>
            </div> */}
        </div>
    )
}

export default Health;