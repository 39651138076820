import React from "react";
import NavBar from "../../NavBar/NavBar.js";
import ProblemCard from "../../../../Shared/Components/ProblemCard/ProblemCard.js";
import ModelCard from "../../../../Shared/Components/ModelCard/ModelCard.js";
import ImpactCard from "../../../../Shared/Components/ImpactCard/ImpactCard.js";
import TimelineCardWrapper from "../../../../Shared/Components/TimelineCardWrapper/TimelineCardWrapper.js";
import "./DiabeticRetinopathy.css";
import { diabetic_retinopathy } from "../../../../Constants/Solutions/retino-constants.js";

const DiabeticRetinopathy = () => {
  return (
    <div>
        <div>
            <NavBar path={diabetic_retinopathy.nextSolution} demo={diabetic_retinopathy.demo} demoUrl={""} demoType={"Internal"}/>
        </div>
        <div className="grid grid-cols-3 py-[0.8rem] px-6 gap-2">
            <div className="col-span-2">
                <div className="flex gap-8">
                    <ProblemCard backgroundImage={diabetic_retinopathy["problem-image"]} problem={diabetic_retinopathy.problem}/>
                    <ModelCard model_heading={diabetic_retinopathy.model_heading} model_question={diabetic_retinopathy.model_question} model_answer={diabetic_retinopathy.model_answer}/>
                </div>
                <div className="flex flex-col pr-4 mt-6">
                    <TimelineCardWrapper information={diabetic_retinopathy.timeline_information}/>
                    <div className="opacity-80 text-[#9DA0A4] col_text mt-1">Collaboration with AIIMS Delhi and Rishikesh</div>
                </div>
            </div>
            <div className="col-span-1">
                <div className="h-full">
                    <ImpactCard information={diabetic_retinopathy.information} content={diabetic_retinopathy.content_array} sdgContent={diabetic_retinopathy.sdgContent} />
                    <div className="flex text-center text-[#838385] source_text gap-1 mt-1">
                        <div>Source:</div>
                        <div className="underline"
                            onClick={() => window.open("https://www.thelancet.com/journals/langlo/article/PIIS2214-109X(22)00411-9/fulltext", "_blank")}>
                                Results from the SMART India population-based screening study
                        </div>
                        <div>&</div>
                        <div className="underline"
                            onClick={() => window.open("https://www.who.int/india/health-topics/mobile-technology-for-preventing-ncds", "_blank")}
                        >
                            WHO
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
};

export default DiabeticRetinopathy;