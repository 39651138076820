import * as React from 'react';
import DomainCard from '../../../Shared/Components/DomainCard/DomainCard';
import { useNavigate } from 'react-router-dom';

const Home = ({}) => {
    let navigate = useNavigate();
    const [path, setPath] = React.useState('');

    const routing = (path) => {
        navigate(path);
    }

    return (
        <div className='flex align-center justify-center w-full h-full my-32'>
            <div className='grid  grid-cols-2 gap-8'>
                <div className='col-span-1'>
                    <DomainCard bgImage={'/Assets/Images/indImage.png'} title={"Health"} description={""} path={"/health"} clicked={routing} />
                </div>
                <div className='col-span-1'>
                    <DomainCard bgImage={'/Assets/Images/indImage.png'} title={"Agriculture"} description={""} path={"/agriculture"} clicked={routing} />
                </div>
            </div>
        </div>
    )
}

export default Home;