// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.problem_card_outer {
    background: #00000057;
}

.problem_card {
    background-blend-mode: darken;
}

.problem_card::before {
    opacity: 0;
    z-index: -1;
}

.problem {
    background-color: "lightgray 50%";
}

.problem_word {
    font-family: "Inter";
    font-size: 1.8vh;
    font-weight: 600;
    line-height: 2vh;
    text-transform: uppercase;
}

.ratio_text {
    font-family: "Hiragino Sans";
    font-size: 2.35vh;
    font-weight: 500;
    line-height: 3vh;
}

.problem_text {
    font-family: "Hiragino Kaku Gothic Std";
    font-size: 2.25vh;
    font-weight: 800;
    line-height: 3vh;
}`, "",{"version":3,"sources":["webpack://./src/app/Shared/Components/ProblemCard/ProblemCard.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;IAC5B,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,uCAAuC;IACvC,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".problem_card_outer {\n    background: #00000057;\n}\n\n.problem_card {\n    background-blend-mode: darken;\n}\n\n.problem_card::before {\n    opacity: 0;\n    z-index: -1;\n}\n\n.problem {\n    background-color: \"lightgray 50%\";\n}\n\n.problem_word {\n    font-family: \"Inter\";\n    font-size: 1.8vh;\n    font-weight: 600;\n    line-height: 2vh;\n    text-transform: uppercase;\n}\n\n.ratio_text {\n    font-family: \"Hiragino Sans\";\n    font-size: 2.35vh;\n    font-weight: 500;\n    line-height: 3vh;\n}\n\n.problem_text {\n    font-family: \"Hiragino Kaku Gothic Std\";\n    font-size: 2.25vh;\n    font-weight: 800;\n    line-height: 3vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
