// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.information_card {
    box-shadow: 0px 0px 5px 6px #00000003;
    padding: 1.6vh 1.5vw;
    border-radius: 10px;
}

.impact_word {
    font-family: "Inter";
    font-size: 2vh;
    font-weight: 600;
    line-height: 2.75vh;
    text-transform: uppercase;
}

.information_number {
    font-family: "Hiragino Kaku Gothic Std";
    font-weight: 800;
    font-size: 3.3vh;
    line-height: 5vh;
    letter-spacing: 0.5%;
    color: #12356D;
}

.information_text {
    font-family: "Lato";
    font-weight: 600;
    font-size: 2vh;
    line-height: 2.5vh;
    color: #12356D;
}`, "",{"version":3,"sources":["webpack://./src/app/Shared/Components/InformationCard/InformationCard.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,uCAAuC;IACvC,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".information_card {\n    box-shadow: 0px 0px 5px 6px #00000003;\n    padding: 1.6vh 1.5vw;\n    border-radius: 10px;\n}\n\n.impact_word {\n    font-family: \"Inter\";\n    font-size: 2vh;\n    font-weight: 600;\n    line-height: 2.75vh;\n    text-transform: uppercase;\n}\n\n.information_number {\n    font-family: \"Hiragino Kaku Gothic Std\";\n    font-weight: 800;\n    font-size: 3.3vh;\n    line-height: 5vh;\n    letter-spacing: 0.5%;\n    color: #12356D;\n}\n\n.information_text {\n    font-family: \"Lato\";\n    font-weight: 600;\n    font-size: 2vh;\n    line-height: 2.5vh;\n    color: #12356D;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
